import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import Modal from 'react-bootstrap4-modal';

const RenderTable = ({ errors, onPageChange, pages, page }) => {
  return(
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Operation" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Invitation ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Access Gateway ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Message" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Date" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {errors.map((error, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{error.id}</TableCell>
                <TableCell>{error.operation}</TableCell>
                <TableCell>{error.invitation_id}</TableCell>
                <TableCell>{error.access_gateway_id}</TableCell>
                <TableCell>{error.message}</TableCell>
                <TableCell>
                  {moment(error.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  )
}

const Errors = ({ setOpenModal, onPageChange, pages, page, errors }) => {
    return (
      <Modal dialogClassName='modal-xl' visible={true} >
        <div className="modal-header">
          <h5 className="modal-title"><FormattedMessage id="Queue Errors" /></h5>
        </div>
        <div className="modal-body">
          {!errors?.length && <NoRecords />}
          {!!errors?.length && (
            <RenderTable
              {...{ onPageChange, pages, page, errors }}
            />
          )}
        </div>
        <div className="modal-footer">
          <Button
            onClick={() => setOpenModal(false)}
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="Close" />
          </Button>
        </div>
      </Modal>
    )
  }

  export default injectIntl(Errors);