import * as React from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Input, Checkbox } from '../Shared/BsInput';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import ContainerCard from '../Shared/container-card';
import { AddGatewaySchema, EditGatewaySchema } from '../../validation-schemas';

const RenderForm = ({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      enableDisable,
                    }) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">
            <FormattedMessage id="Configuration" />
          </Typography>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.config.create_access_code_url}
            onChange={handleChange}
            name="config.create_access_code_url"
            label={<FormattedMessage id="URL to create access code" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.config.update_access_code_url}
            onChange={handleChange}
            name="config.update_access_code_url"
            label={<FormattedMessage id="URL to update access code" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.config.cancel_access_code_url}
            onChange={handleChange}
            name="config.cancel_access_code_url"
            label={<FormattedMessage id="URL to cancel access code" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.config.get_access_code_url}
            onChange={handleChange}
            name="config.get_access_code_url"
            label={<FormattedMessage id="URL to get access code" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.config.stats_url}
            onChange={handleChange}
            name="config.stats_url"
            label={<FormattedMessage id="URL to get stats" />}
            errors={errors}
          />
          <Input
            type="number"
            value={values.config.schedule_before_date}
            onChange={handleChange}
            name="config.schedule_before_date"
            label={<FormattedMessage id="Minutes before the invitation date we will create the access code" />}
            help={<FormattedMessage id="If you want to use the standard logic to decide put a very high value in the magnitude of the hundred thousands" />}
            errors={errors}
          />
          <div>
            <Checkbox
              value={values.config.merge_schedule_invites}
              onChange={handleChange}
              name="config.merge_schedule_invites"
              errors={errors}
            /> <FormattedMessage id="Merge conflicting invite schedules before sending to gateway" />
          </div>
          <div>
            <Checkbox
              value={values.config.anonymize}
              onChange={handleChange}
              name="config.anonymize"
              errors={errors}
            /> <FormattedMessage id="Anonymize user and visitor information" />
          </div>
          <div>
            <Checkbox
              value={values.config.generate_card_number}
              onChange={handleChange}
              name="generate_card_number"
              errors={errors}
            /> <FormattedMessage id="This gateway generates card number" />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <br/><br/>
          <div>
            <Checkbox
              value={values.config.create_users}
              onChange={handleChange}
              name="config.create_users"
              errors={errors}
            /> <FormattedMessage id="Create users in 3rd party system" />
          </div>
          <Input
            type="text"
            value={values.config.create_users_url}
            onChange={handleChange}
            name="config.create_users_url"
            label={<FormattedMessage id="URL to create users in 3rd party" />}
            errors={errors}
          />
          <hr />
          <div>
            <Checkbox
              value={values.config.update_users}
              onChange={handleChange}
              name="config.update_users"
              errors={errors}
            /> <FormattedMessage id="Update users in 3rd party system" />
          </div>
          <Input
            type="text"
            value={values.config.update_users_url}
            onChange={handleChange}
            name="config.update_users_url"
            label={<FormattedMessage id="URL to update users in 3rd party system" />}
            errors={errors}
          />
          <hr />
          <div>
            <Checkbox
              value={values.config.delete_users}
              onChange={handleChange}
              name="config.delete_users"
              errors={errors}
            /> <FormattedMessage id="Delete users in 3rd party system" />
          </div>
          <Input
            type="text"
            value={values.config.delete_users_url}
            onChange={handleChange}
            name="config.delete_users_url"
            label={<FormattedMessage id="URL to delete users in 3rd party system" />}
            errors={errors}
          />
          <hr />
          <div>
            <Checkbox
              value={values.config.change_users_code}
              onChange={handleChange}
              name="config.change_users_code"
              errors={errors}
            /> <FormattedMessage id="Change personnel access code in 3rd party system every 24hs" />
          </div>
          <Input
            type="text"
            value={values.config.change_users_code_url}
            onChange={handleChange}
            name="config.change_users_code_url"
            label={<FormattedMessage id="URL to change access code in 3rd party system" />}
            errors={errors}
          />
        </Grid>
        <Grid container spacing={3}>
          <hr />
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
            >
              <FormattedMessage id="Save" />
            </Button>
          </Grid>
            <Grid item xs={12} md={6}>
              <div style={{ display: values.id ? 'inherit' : 'none'}}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => enableDisable(values.disabled)}
                  fullWidth
                >
                  <FormattedMessage id={values.disabled ? "Enable" : "Disable"} />
                </Button>
              </div>
            </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, gateway, isLoading, enableDisable }) => {
  if (isLoading) {
    return null;
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i>&nbsp;
          {
            gateway.id ? <FormattedMessage id="Edit gateway" /> : <FormattedMessage id="Create gateway" />
          }
        </Typography>
      }
      content={
        <Formik
          initialValues={gateway}
          validationSchema={gateway.id ? EditGatewaySchema : AddGatewaySchema}
          enableReinitialize={true}
          onSubmit={
            async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }
          }
          render={p => <RenderForm {...p} enableDisable={enableDisable} />}
        />
      }
    />
  );
}
