import * as React from 'react';
import * as _ from 'lodash';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import ListGuest from '../../components/Keys/listGuest';
import ListPermanent from '../../components/Keys/listPermanent';
import * as BuildingsApi from '../../api/buildings';
import {
  AppBar,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ContainerCard from '../../components/Shared/container-card';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography component="div">{children}</Typography>
        </div>
      )}
    </div>
  );
}

class KeysContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      results: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        type: 'guest',
        key: '',
        valid_until: null,
      },
      tab: 0,
    };

    this.onDestroy = this.onDestroy.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async onDestroy(body) {
    await BuildingsApi.destroyKey(this.state.buildingId, { ids: body });

    toast.success(
      this.props.intl.formatMessage({ id: 'The keys were deleted' })
    );
    this.onPageChange({ page: 1 });
  }

  async onPageChange({ page, ...rest }) {
    const body = {
      limit: 10,
      offset: (page - 1) * 10,
      ...(_.isEmpty(rest) ? this.state.where : rest),
    }

    const { data, headers } = this.state.tab === 0 ? await BuildingsApi.listKeys(
      this.state.buildingId,
      body
    ) : await BuildingsApi.listBuildingKeysList(
      this.state.buildingId,
      body
    );

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      results: data,
      pages,
      page,
      where: _.isEmpty(rest) ? this.state.where : rest,
    });
  }

  async handleChange(event, newValue) {
    const where = this.state.where;
    where.type = newValue === 0 ? 'guest' : 'permanent'
    await this.setState({ tab: newValue, where, results: [] });
    this.onPageChange({ page: 1 })
  };

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <ContainerCard
        xs={12}
        md={12}
        header={
          <Typography variant="h6">
            <i className="fa fa-key"></i> "{this.state.building.name}" -{' '}
            <FormattedMessage id="Access codes" />
          </Typography>
        }
        content={
          <React.Fragment>
            <AppBar style={{margin: "-16px 0 32px -16px", width: "calc(100% + 32px)"}} position="static" color="default">
            <Tabs value={this.state.tab} onChange={this.handleChange} variant="fullWidth">
              <Tab label={<FormattedMessage id="Guest" />} />
              <Tab label={<FormattedMessage id="Permanent" />} />
            </Tabs>
            </AppBar>
            <TabPanel value={this.state.tab} index={0}>
              <ListGuest
                building={this.state.building}
                results={this.state.results}
                page={this.state.page}
                pages={this.state.pages}
                onSubmit={this.onSubmit}
                onPageChange={this.onPageChange}
                onDestroy={this.onDestroy}
                loading={this.state.isLoading}
              />
            </TabPanel>
            <TabPanel value={this.state.tab} index={1}>
              <ListPermanent
                building={this.state.building}
                results={this.state.results}
                page={this.state.page}
                pages={this.state.pages}
                onSubmit={this.onSubmit}
                onPageChange={this.onPageChange}
                onDestroy={this.onDestroy}
                initialValues={this.state.where}
                loadin={this.state.isLoading}
              />
            </TabPanel>
          </React.Fragment>
        }
      />
    );
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });

    const { data } = await BuildingsApi.get(this.state.buildingId);

    this.setState({
      building: data,
      isLoading: false,
    });
  }
}

export default injectIntl(KeysContainer);
