import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { loggedIn, logout } from '../../actions/auth';
import { changeLanguage } from '../../actions/ui';
import * as AuthApi from '../../api/auth';

import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../assets/css/styles.css';
import packageJson from '../../../package.json';

import Navbar from './navbar';
import UnAuthenticatedNavbar from './unauthenticated-navbar';
import Routes from './routes';
import Loading from '../../components/Shared/Loading';

import pt from '../../i18n/pt.json';
import en from '../../i18n/en.json';

const LANGUAGES = {
  pt,
  en,
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#37474f',
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        whiteSpace: 'normal',
      },
    },
  },
});

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
      loading: true,
    };
    this.logout = this.logout.bind(this);

    if (localStorage.getItem('localVersion') !== packageJson.version) {
      localStorage.setItem('localVersion', packageJson.version);
      window.location.reload(true);
    }
  }

  render() {
    const { isLoggedIn, user, changeLanguage, language } = this.props;
    const loggedIn = isLoggedIn && !!user;
    const { loading } = this.state;

    if (loading) {
      return null;
    }

    if (!loggedIn) {
      return (
        <Router>
          <ThemeProvider theme={theme}>
            <UnAuthenticatedNavbar>
              <div style={{ marginTop: '8vh' }}>
                <Routes loggedIn={loggedIn} />
              </div>
              <Loading visible={this.props.loading} />
              <ToastContainer position="bottom-left" />
            </UnAuthenticatedNavbar>
          </ThemeProvider>
        </Router>
      );
    }

    return (
      <IntlProvider
        messages={LANGUAGES[language]}
        locale={language}
        defaultLocale={language}
      >
        <Router>
          <ThemeProvider theme={theme}>
            <Navbar
              loggedIn={loggedIn}
              logout={this.logout}
              user={user}
              changeLanguage={changeLanguage}
              language={language}
            >
              <div style={{ marginTop: '8vh' }}>
                <Routes loggedIn={loggedIn} />
              </div>
              <Loading visible={this.props.loading} />
              <ToastContainer position="bottom-left" />
            </Navbar>
          </ThemeProvider>
        </Router>
      </IntlProvider>
    );
  }

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      const { data } = await AuthApi.me();

      this.props.loggedIn({
        token: localStorage.getItem('token'),
        user: data,
      });

      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  logout() {
    this.props.logout();
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.loggedIn,
  user: state.auth.user,
  loading: state.ui.loading > 0,
  language: state.ui.language,
});

const mapDispatchToProps = dispatch => ({
  loggedIn: data => dispatch(loggedIn(data)),
  logout: () => dispatch(logout()),
  changeLanguage: lang => dispatch(changeLanguage(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
