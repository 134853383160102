import detectBrowserLanguage from 'detect-browser-language';
import { API_REQUEST, CHANGE_LANGUAGE } from '../constants';

const lang = (detectBrowserLanguage() || '').substr(0, 2).toLowerCase();

const defaultState = {
  loading: 0,
  language: lang === 'pt' ? 'pt' : 'en',
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case API_REQUEST: {
      return Object.assign({}, state, {
        loading: state.loading + action.payload,
      });
    }
    default: {
      return state;
    }
  }
}
