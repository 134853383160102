import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import TosList from '../../components/Tos/index';
import * as TosApi from '../../api/tos';

class TosIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tos: [],
      pages: 0,
      page: 1,
      isLoading: true,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  async destroy(id) {
    const onConfirmClick = async () => {
      await TosApi.destroy(id);

      toast.success(
        this.props.intl.formatMessage({ id: 'The TOS revision was deleted' })
      );
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Delete record? This action is irreversible',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  async onPageChange({ page, ...where }) {
    const { data, headers } = await TosApi.list({
      limit: 20,
      offset: (page - 1) * 20,
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      tos: data,
      pages,
      page,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <TosList
        tos={this.state.tos}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        destroy={this.destroy}
      />
    );
  }
}

export default injectIntl(TosIndex);
