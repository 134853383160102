import axios from 'axios';
import { toast } from 'react-toastify';
import * as moment from 'moment';
import * as _ from 'lodash';
import { logout } from '../actions/auth';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function catchError(error) {
  const { status, data } = error.response;

  switch (status) {
    case 403:
    case 401:
      store.dispatch(logout());
      window.location.href = '/login';
      break;
    default: {
      toast.error(data.message || data.error);
    }
  }

  store.dispatch(res());

  throw error;
}

export function removeEmptyQs(params) {
  return Object.keys(params).reduce((acc, key) => {
    if (params[key] && params[key] !== '__none__') {
      acc[key] = params[key];
    } else if (_.isBoolean(params[key])) {
      acc[key] = params[key] ? 1 : 0;
    }

    return acc;
  }, {});
}

export default function request(
  { method, params, body, path },
  disableLoading = false
) {
  const options = {
    method,
    url: `${BASEURL}${path}`,
    headers: {},
  };

  if (localStorage.getItem('token')) {
    options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  if (params) {
    if (params.where) {
      params.where = removeEmptyQs(params.where);
    } else {
      params = {
        ..._.pick(params, ['attributes', 'limit', 'offset', 'order']),
        ...removeEmptyQs(
          _.omit(params, ['attributes', 'limit', 'offset', 'order'])
        ),
      };
    }

    options.params = params;
  }

  if (body) {
    options.data = body;
  }

  if (!disableLoading) {
    store.dispatch(req());
  }

  return axios(options)
    .then(response => {
      if (!disableLoading) {
        store.dispatch(res());
      }

      return response;
    })
    .catch((e) => {
      catchError(e);
      return { error: e };
    });
}
