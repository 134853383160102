import * as React from 'react';
import Mask from 'react-text-mask';
import InputErrorClass from './InputErrorClass';
import {
  TextField,
  Select as MaterialSelect,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Checkbox as MaterialCheckbox,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

const MASKS = {
  'cpf-input': [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
  'rg-input': [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
  ],
  'rne-input': [
    /[a-zA-Z]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /[a-zA-Z]/,
  ],
};

const getHelpOrError = (errors, name) => {
  if (!errors) {
    return null;
  }

  if (errors[name]) {
    return errors[name];
  }

  if (name.includes('.')) {
    const split = name.split('.');

    if (errors[split[0]]) {
      let tmp = errors[split[0]];

      for (let i = 1; i < split.length; i += 1) {
        if (tmp[split[i]]) {
          tmp = tmp[split[i]];
        } else {
          tmp = null;
        }
      }

      if (tmp) {
        return tmp;
      }
    }
  }

  return null;
};

function TextMaskCustom(props) {
  const { inputRef, mask, ...other } = props;

  return (
    <Mask
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={MASKS[other.id]}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  );
}

export const MaskedInput = props => {
  const { type, label, name, help, errors, value, ...rest } = props;

  return (
    <TextField
      label={label || ''}
      margin="dense"
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      error={!!getHelpOrError(errors, name)}
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
      helperText={getHelpOrError(errors, name) || help}
      name={name}
      {...rest}
    />
  );
};

export const Input = props => {
  const { type, label, name, errors, value, help, ...rest } = props;

  const error = getHelpOrError(errors, name);

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={type || 'text'}
      value={value || ''}
      fullWidth={true}
      helperText={error || help}
      error={!!error}
      {...rest}
    />
  );
};

export const Select = props => {
  const { type, label, name, errors, value, options, help, ...rest } = props;

  return (
    <FormControl fullWidth style={{ paddingTop: '8px' }}>
      <InputLabel style={{ paddingTop: '8px' }}>{label || ''}</InputLabel>
      <MaterialSelect
        margin="dense"
        name={name}
        value={value || ''}
        fullWidth={true}
        error={!!getHelpOrError(errors, name)}
        {...rest}
        MenuProps={{
          style: {
            zIndex: 3000,
          }
        }}
      >
        {options.map((o, i) => (
          <MenuItem key={i} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </MaterialSelect>
      <FormHelperText error={!!getHelpOrError(errors, name)}>
        {getHelpOrError(errors, name) || help}
      </FormHelperText>
    </FormControl>
  );
};

export const InputAddon = props => {
  const { type, label, name, errors, onClick, ...rest } = props;

  return (
    <div className="form-group">
      {label && <label>{label}</label>}
      <div className="input-group">
        <input
          className={InputErrorClass({ errors, name })}
          name={name}
          type={type || 'text'}
          {...rest}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={onClick}
          >
            <i className="fa fa-download"></i>
          </button>
        </div>
      </div>
      {errors[name] && <p className="invalid-feedback">{errors[name]}</p>}
    </div>
  );
};

export const Textarea = props => {
  const { label, name, value, help, errors, ...rest } = props;

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={'text'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(errors, name) || help}
      error={!!getHelpOrError(errors, name)}
      {...rest}
      multiline={true}
      rows={3}
    />
  );
};

export const Checkbox = props => {
  const { label, name, value, ...rest } = props;

  return (
    <MaterialCheckbox
      style={{ paddingLeft: '0' }}
      checked={value}
      value={value}
      name={name}
      {...rest}
    />
  );
};

export const Password = props => {
  const [showPwd, setShowPwd] = React.useState(false);
  const { type, label, name, errors, value, help, ...rest } = props;

  const changeShowPwd = () => {
    setShowPwd(!showPwd);
  };

  return (
    <TextField
      label={label || ''}
      margin="dense"
      name={name}
      type={showPwd ? 'text' : 'password'}
      value={value || ''}
      fullWidth={true}
      helperText={getHelpOrError(errors, name) || help}
      error={!!getHelpOrError(errors, name)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={changeShowPwd}
            >
              {showPwd ? (
                <i className="fa fa-eye"></i>
              ) : (
                <i className="fa fa-eye-slash"></i>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
