import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import { Input, Select, MaskedInput } from '../Shared/BsInput';

const RenderTable = ({ onPageChange, pages, page, users, forceLogout }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Email" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Active" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Profile complete" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Created" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {users.map((b, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{b.id}</TableCell>
                <TableCell>
                  {b.surname}, {b.name}
                </TableCell>
                <TableCell>{b.deleted_at ? 'Deleted' : b.email}</TableCell>
                <TableCell>{b.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>{b.profile_completed_at ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  {moment(b.createdAt).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/app/users/${b.id}/edit`}
                    target="_blank"
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="View" />
                  </Button>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    onClick={() => forceLogout(b.id)}
                    fullWidth
                  >
                    <FormattedMessage id="Logout from all devices" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={<FormattedMessage id="Surname" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={<FormattedMessage id="Email" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.cellphone}
            onChange={handleChange}
            name="cellphone"
            label={<FormattedMessage id="Cellphone" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MaskedInput
            type="text"
            placeholder="xxx.xxx.xxx-xx"
            value={values.cpf}
            onChange={handleChange}
            name="cpf"
            id="cpf-input"
            label={<FormattedMessage id="CPF number" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id_number}
            onChange={handleChange}
            name="id_number"
            label={<FormattedMessage id="ID Number" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.building}
            onChange={handleChange}
            name="building"
            label={<FormattedMessage id="Building" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.company}
            onChange={handleChange}
            name="company"
            label={<FormattedMessage id="Company" />}
            errors={errors}
          />
        </Grid> 
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.facility_code}
            onChange={handleChange}
            name="facility_code"
            label={<FormattedMessage id="Facility code" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.key}
            onChange={handleChange}
            name="key"
            label={<FormattedMessage id="Access code" />}
            errors={errors}
          />
        </Grid> 
        <Grid item xs={6} md={3}>
          <Select
            value={values.active}
            onChange={handleChange}
            name="active"
            label={<FormattedMessage id="Active" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Active + inactive" />,
              },
              { value: '0', label: <FormattedMessage id="Only inactive" /> },
              { value: '1', label: <FormattedMessage id="Only active" /> },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.admin}
            onChange={handleChange}
            name="admin"
            label={<FormattedMessage id="Admin" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Admin + regular users" />,
              },
              {
                value: '0',
                label: <FormattedMessage id="Only regular users" />,
              },
              { value: '1', label: <FormattedMessage id="Only admins" /> },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.order}
            onChange={handleChange}
            name="order"
            label={<FormattedMessage id="Order" />}
            options={[
              { value: 'id desc', label: 'ID desc' },
              { value: 'id asc', label: 'ID asc' },
              { value: 'email desc', label: 'Email desc' },
              { value: 'email asc', label: 'Email asc' },
              { value: 'surname desc', label: 'Surname desc' },
              { value: 'surname asc', label: 'Surname asc' },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.should_change_password}
            onChange={handleChange}
            name="should_change_password"
            label={<FormattedMessage id="Should change password" />}
            options={[
              { value: '__none__', label: <FormattedMessage id="None" /> },
              {
                value: '1',
                label: <FormattedMessage id="Only users who should change" />,
              },
              {
                value: '0',
                label: (
                  <FormattedMessage id="Only users who should not change" />
                ),
              },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.profile_completed_at}
            onChange={handleChange}
            name="profile_completed_at"
            label={<FormattedMessage id="Profile complete" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Irrelevant" />,
              },
              {
                value: '1',
                label: (
                  <FormattedMessage id="Only users with profile complete" />
                ),
              },
              {
                value: '0',
                label: (
                  <FormattedMessage id="Only users with profile incomplete" />
                ),
              },
            ]}
            errors={errors}
          />
          <Typography component="span">
            <Tooltip title="Users who completed their profile. This applies to users completing the profile for the first time as well as users who changed their profile after an admin review">
              <i
                className="fa fa-question-circle"
                style={{
                  color: '#004692',
                  cursor: 'pointer',
                  fontSize: '1.2em',
                }}
              ></i>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.profile_approved_at}
            onChange={handleChange}
            name="profile_approved_at"
            label={<FormattedMessage id="Profile approved" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Irrelevant" />,
              },
              {
                value: '1',
                label: (
                  <FormattedMessage id="Only users with profile approved" />
                ),
              },
              {
                value: '0',
                label: (
                  <FormattedMessage id="Only users with profile not approved" />
                ),
              },
            ]}
            errors={errors}
          />
          <Typography component="span">
            <Tooltip title="Users who are already verified and can fully use the application. Profile approved and profile rejected are mutually exclusive">
              <i
                className="fa fa-question-circle"
                style={{
                  color: '#004692',
                  cursor: 'pointer',
                  fontSize: '1.2em',
                }}
              ></i>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.profile_rejected_at}
            onChange={handleChange}
            name="profile_rejected_at"
            label={<FormattedMessage id="Profile rejected" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Irrelevant" />,
              },
              {
                value: '1',
                label: (
                  <FormattedMessage id="Only users with profile rejected" />
                ),
              },
              {
                value: '0',
                label: (
                  <FormattedMessage id="Only users with profile not rejected" />
                ),
              },
            ]}
            errors={errors}
          />
          <Typography component="span">
            <Tooltip title="Users who were rejected during the review. This field will be set until the user is later approved">
              <i
                className="fa fa-question-circle"
                style={{
                  color: '#004692',
                  cursor: 'pointer',
                  fontSize: '1.2em',
                }}
              ></i>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.registration_step}
            onChange={handleChange}
            name="registration_step"
            label={<FormattedMessage id="Registration step" />}
            options={[
              {
                value: '__none__',
                label: <FormattedMessage id="Irrelevant" />,
              },
              {
                value: 'CELLPHONE_VERIFICATION',
                label: <FormattedMessage id="Cellphone verification" />,
              },
              {
                value: 'EMAIL_VERIFICATION',
                label: <FormattedMessage id="Email verification" />,
              },
              {
                value: 'BASIC_INFORMATION',
                label: <FormattedMessage id="Basic information" />,
              },
              {
                value: 'PROFILE_PICTURE',
                label: <FormattedMessage id="Profile picture" />,
              },
              {
                value: 'DOCUMENTS',
                label: <FormattedMessage id="Documents" />,
              },
              {
                value: 'CONFIRM_INFORMATION',
                label: <FormattedMessage id="Confirm information" />,
              },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({
  onPageChange,
  pages,
  page,
  users,
  forceLogout,
  initialValues,
}) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-users"></i> <FormattedMessage id="Users" />
        </Typography>
      }
      content={
        <React.Fragment>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await onPageChange({ page: 1, ...values });
                setSubmitting(false);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={RenderForm}
          />
          <hr />
          {!users.length && <NoRecords />}
          {!!users.length && (
            <RenderTable
              {...{ onPageChange, pages, page, users, forceLogout }}
            />
          )}
        </React.Fragment>
      }
    />
  );
};
