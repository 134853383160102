import * as React from 'react';

class UploadPicture extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      file: '',
      imagePreviewUrl: '',
    };
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  _handleSubmit(e) {
    e.preventDefault();

    this.props.onSubmit(this.state.file);
    this.setState({ edit: false, file: '', imagePreviewUrl: '' });
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    let { imagePreviewUrl } = this.state;
    let src =
      'https://www.flynz.co.nz/wp-content/uploads/profile-placeholder-300x300.png';

    const { profile_picture } = this.props;

    if (profile_picture) {
      src = profile_picture;
    }

    if (imagePreviewUrl) {
      src = imagePreviewUrl;
    }

    return (
      <div>
        <div className="text-center">
          <img
            src={src}
            className="img-thumbnail"
            style={{ maxWidth: '40%' }}
          />
          <div>
            <a href="javascript:void(0)" onClick={this.toggleEdit}>
              <i className="fa fa-pencil"></i>
            </a>
          </div>
        </div>
        {this.state.edit && (
          <form onSubmit={e => this._handleSubmit(e)}>
            <div className="form-group">
              <input
                className="form-control"
                type="file"
                onChange={e => this._handleImageChange(e)}
              />
            </div>
            {this.state.file && (
              <button
                className="btn btn-sm btn-dark btn-block"
                type="submit"
                onClick={e => this._handleSubmit(e)}
              >
                Upload new picture
              </button>
            )}
          </form>
        )}
      </div>
    );
  }
}

export default UploadPicture;
