import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import { Input, Select } from '../Shared/BsInput';
import * as UsersApi from '../../api/users';

const getName = u =>
  u.name ? `${u.name} ${u.surname}` : u.email || u.cellphone;

const RenderTable = ({ onPageChange, pages, page, meetings }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Building" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Date" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Host" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Created" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {meetings.map((meeting, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{meeting.id}</TableCell>
                <TableCell>{meeting.Building.name}</TableCell>
                <TableCell>
                  {moment(meeting.date_from).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>{getName(meeting.Host)}</TableCell>
                <TableCell>
                  {moment(meeting.createdAt).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/app/meetings/${meeting.id}`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="View" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  buildings,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      _.throttle(async (params, callback) => {
        const { data } = await UsersApi.autocomplete(params);

        return callback(data);
      }, 300),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ query: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={1}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <Input
            type="text"
            value={values.invitation_id}
            onChange={handleChange}
            name="invitation_id"
            label={<FormattedMessage id="Invitation ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            type="text"
            value={values.building_id}
            onChange={handleChange}
            name="building_id"
            label={<FormattedMessage id="Building" />}
            errors={errors}
            options={[
              { value: '', label: 'All' },
              ...buildings.map(b => ({ label: b.name, value: b.id })),
            ]}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DateTimePicker
            value={values.date_from}
            onChange={value => {
              if (value) {
                setFieldValue('date_from', value.format());
              } else {
                setFieldValue('date_from', null);
              }
            }}
            ampm={false}
            fullWidth
            clearable
            autoOk
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="Start date" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DateTimePicker
            value={values.date_to}
            onChange={value => {
              if (value) {
                setFieldValue('date_to', value.format());
              } else {
                setFieldValue('date_to', null);
              }
            }}
            ampm={false}
            fullWidth
            clearable
            autoOk
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="End date" />}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Autocomplete
            getOptionLabel={o =>
              typeof o === 'string' ? o : `${o.name} ${o.surname} - ${o.email}`
            }
            options={options}
            autoComplete
            includeInputInList
            onChange={(e, v) => {
              if (v) {
                setFieldValue('host_user_id', v.id);
              } else {
                setFieldValue('host_user_id', null);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Host"
                margin="dense"
                fullWidth
                help={<FormattedMessage id="Type and select from the list" />}
                onChange={handleInputChange}
              />
            )}
            renderOption={o => {
              return (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {`${o.name} ${o.surname} - ${o.email}`}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <Autocomplete
            getOptionLabel={o =>
              typeof o === 'string' ? o : `${o.name} ${o.surname} - ${o.email}`
            }
            options={options}
            autoComplete
            includeInputInList
            onChange={(e, v) => {
              if (v) {
                setFieldValue('guest_user_id', v.id);
              } else {
                setFieldValue('guest_user_id', null);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Guest"
                margin="dense"
                fullWidth
                help={<FormattedMessage id="Type and select from the list" />}
                onChange={handleInputChange}
              />
            )}
            renderOption={o => {
              return (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {`${o.name} ${o.surname} - ${o.email}`}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <DateTimePicker
            value={values.createdAt}
            onChange={value => {
              if (value) {
                setFieldValue('createdAt', value.format());
              } else {
                setFieldValue('createdAt', null);
              }
            }}
            ampm={false}
            fullWidth
            clearable
            autoOk
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="Creation date" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.order}
            onChange={handleChange}
            name="order"
            label={<FormattedMessage id="Order" />}
            options={[
              { value: 'id desc', label: 'ID desc' },
              { value: 'id asc', label: 'ID asc' },
              { value: 'date_from desc', label: 'Start date desc' },
              { value: 'date_from asc', label: 'Start date asc' },
              { value: 'date_to desc', label: 'End date desc' },
              { value: 'date_to asc', label: 'End date asc' },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3} className="mt-3">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, pages, page, meetings, buildings }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-envelope"></i> <FormattedMessage id="Meetings" />
        </Typography>
      }
      content={
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <Formik
              initialValues={{
                id: '',
                invitation_id: '',
                host_user_id: '',
                guest_user_id: '',
                building_id: '',
                date_from: null,
                date_to: null,
                createdAt: null,
                order: 'date_from desc',
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await onPageChange({ page: 1, ...values });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => <RenderForm {...p} {...{ buildings }} />}
            />
            <hr />
            {!meetings.length && <NoRecords />}
            {!!meetings.length && (
              <RenderTable {...{ onPageChange, pages, page, meetings }} />
            )}
          </div>
        </MuiPickersUtilsProvider>
      }
    />
  );
};
