import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export default ({ user }) => {
  return (
    <Paper style={{ padding: '10px' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Date" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Triggered by" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Type of event" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="Meta" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user.Events.map((event, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  {moment(event.created_at).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>
                  {event.triggered_by_user_id
                    ? event.TriggeredBy.email
                    : 'Self'}
                </TableCell>
                <TableCell>
                  {event.type} / {event.subtype}
                </TableCell>
                <TableCell>
                  {isEmpty(event.meta) ? (
                    '-'
                  ) : (
                    <pre>{JSON.stringify(event.meta, null, 2)}</pre>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
