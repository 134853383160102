import * as React from 'react';
import * as _ from 'lodash';
import GatewayIndex from '../../components/Gateways/index';
import * as GatewaysApi from '../../api/gateways';

export default class GatewayIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gateways: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        id: '',
        name: '',
        order: 'id desc',
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...where }) {
    const { data, headers } = await GatewaysApi.list({
      limit: 10,
      offset: (page - 1) * 10,
      attributes: 'id,name,disabled',
      ...(_.isEmpty(where) ? this.state.where : where),
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      gateways: data,
      pages,
      page,
      where: (_.isEmpty(where) ? this.state.where : where),
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <GatewayIndex
        gateways={this.state.gateways}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}
