import * as React from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl } from 'react-intl';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import BuildingUsers from '../../components/Buildings/users';
import * as BuildingsApi from '../../api/buildings';

class BuildingUsersContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      users: [],
      companies: [],
      pages: 0,
      page: 1,
      building: null,
      isLoading: true,
      where: {
        email: '',
        name: '',
        surname: '',
        role: '',
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...rest }) {
    const { data, headers } = await BuildingsApi.users(this.state.buildingId, {
      limit: 10,
      offset: (page - 1) * 10,
      ...(!isEmpty(rest) ? rest : this.state.where),
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      users: data,
      pages,
      page,
      where: !isEmpty(rest) ? rest : this.state.where,
    });
  }

  removeUser = async id => {
    const onConfirmClick = async () => {
      await BuildingsApi.removeUser(this.state.buildingId, id);

      toast.success(
        this.props.intl.formatMessage({ id: 'The user role has been removed' })
      );
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Remove role for the user in this building?',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  };

  editRole = async (userBuildingId, newRole, company) => {
    await BuildingsApi.editRole(this.state.buildingId, userBuildingId, newRole, company);
  };

  addRole = async (userId, role, company) => {
    await BuildingsApi.addRole(this.state.buildingId, userId, role, company);
  };

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <BuildingUsers
        users={this.state.users}
        pages={this.state.pages}
        page={this.state.page}
        isLoading={this.state.isLoading}
        buildingId={this.state.buildingId}
        building={this.state.building}
        onPageChange={this.onPageChange}
        removeUser={this.removeUser}
        editRole={this.editRole}
        addRole={this.addRole}
        companies={this.state.companies}
      />
    );
  }

  async componentDidMount() {
    const res = await Promise.all([
      BuildingsApi.get(this.state.buildingId),
      BuildingsApi.listCompanies(this.state.buildingId, {
        limit: 100,
        attributes: 'id,name',
        order: 'name desc',
      }),
    ]);

    if (this.state.email) {
      await this.searchUser({ email: this.state.email });
    }

    this.setState({
      building: res[0].data,
      companies: res[1].data,
      isLoading: false,
    });

    this.onPageChange({ page: 1 });
  }
}

export default injectIntl(BuildingUsersContainer);
