import request from './index';

export function createProcess(body) {
    return request({
      method: 'POST',
      path: `/api/admin/scheduled-processes/`,
      body
    });
  }

export function getLastProcess(building_id) {
    return request({
        method: 'GET',
        path: `/api/admin/scheduled-processes?${building_id ? "building_id=" + building_id : "" }&limit=1&offset=0`,
    });
}