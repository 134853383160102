import axios from 'axios';
import * as _ from 'lodash';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/buildings',
  });
}

export function importUsersSimulate(file, rest) {
  const fd = new FormData();
  fd.append('sheet', file);

  Object.keys(rest).forEach(key => {
    if (rest[key]) {
      fd.append(key, rest[key]);
    }
  });

  store.dispatch(req());

  return axios
    .post(`${BASEURL}/api/admin/buildings/import-users/simulate`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function importUsers(file, rest, onProgress) {
  const fd = new FormData();
  fd.append('sheet', file);

  Object.keys(rest).forEach(key => {
    if (rest[key]) {
      fd.append(key, rest[key]);
    }
  });

  store.dispatch(req());

  return axios
    .post(`${BASEURL}/api/admin/buildings/import-users`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      onDownloadProgress: onProgress,
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/admin/buildings/${id}`,
  });
}

export function create(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/admin/buildings',
  });
}

export function update(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/admin/buildings/${id}`,
  });
}

export function users(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/users`,
  });
}

export function addUser(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/admin/buildings/${id}/add-user`,
  });
}

export function searchUserByKey(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/building-keys/search`,
  });
}

export function searchUser(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/users/search`,
  });
}

export function removeUser(buildingId, id) {
  return request({
    method: 'DELETE',
    path: `/api/admin/buildings/${buildingId}/remove-user/${id}`,
  });
}

export function removeRoles(buildingId, userId) {
  return request({
    method: 'DELETE',
    path: `/api/admin/buildings/${buildingId}/remove-roles/${userId}`,
  });
}

export function listCompanies(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/companies`,
  });
}

export function removeCompany(id) {
  return request({
    method: 'DELETE',
    path: `/api/admin/companies/${id}`,
  });
}

export function getCompany(buildingId, companyId) {
  return request({
    method: 'GET',
    path: `/api/admin/buildings/${buildingId}/companies/${companyId}`,
  });
}

export function createCompany(buildingId, body) {
  const fd = new FormData();

  Object.keys(body)
    .filter(key => {
      if (_.isString(body[key])) {
        return !!body[key];
      }

      if (_.isBoolean(body[key])) {
        return true;
      }

      if (_.isObject(body[key])) {
        return true;
      }

      return false;
    })
    .forEach(key => {
      fd.append(key, body[key]);
    });

  store.dispatch(req());

  return axios
    .post(`${BASEURL}/api/admin/buildings/${buildingId}/companies`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function updateCompany(buildingId, companyId, body) {
  const fd = new FormData();

  Object.keys(body)
    .filter(key => {
      if (_.isString(body[key])) {
        return !!body[key];
      }

      if (_.isBoolean(body[key])) {
        return true;
      }

      if (_.isObject(body[key])) {
        return true;
      }

      return false;
    })
    .forEach(key => {
      fd.append(key, body[key]);
    });

  store.dispatch(req());

  return axios
    .put(
      `${BASEURL}/api/admin/buildings/${buildingId}/companies/${companyId}`,
      fd,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function listKeys(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/keys`,
  });
}

export function listBuildingKeysList(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/buildings/${id}/keys/list`,
  });
}

export function createKey(buildingId, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/admin/buildings/${buildingId}/keys`,
  });
}

export function createBuildingKeysList(buildingId, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/admin/buildings/${buildingId}/keys/list`,
  });
}

export function destroyKey(buildingId, body) {
  return request({
    body,
    method: 'DELETE',
    path: `/api/admin/buildings/${buildingId}/keys`,
  });
}

export function getUsersExportGateway(id) {
  return request({
    method: 'GET',
    path: `/api/admin/buildings/${id}/export-gateway`,
  });
}

export function getAllUsersExportGateway(id) {
  return request({
    method: 'GET',
    path: `/api/admin/buildings/${id}/export-all-gateway`,
  });
}

export function sendUsersGateway(body) {
  console.log(body)
  return request({
    body,
    method: 'POST',
    path: `/api/admin/buildings/export-gateway`,
  }, true);
}

export function deactivate(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/buildings/deactivate/${id}`,
  });
}

export function activate(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/buildings/activate/${id}`,
  });
}

export function editRole(buildingId, userBuildingId, newRole, company) {
  return request({
    method: 'PUT',
    path: `/api/admin/buildings/${buildingId}/edit-role/${userBuildingId}`,
    body: { newRole, company }
  });
};

export function addRole(buildingId, userId, role, company) {
  return request({
    method: 'POST',
    path: `/api/admin/buildings/${buildingId}/add-role/${userId}`,
    body: { role, company }
  });
};
