import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import GatewayForm from '../../components/Gateways/form';
import * as GatewaysApi from '../../api/gateways';

const nullify = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (_.isString(obj[key]) && obj[key].trim().length === 0) {
      acc[key] = null;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

class GatewayCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gateway: {
        name: '',
        config: {
          stats_url: null,
          create_access_code_url: null,
          update_access_code_url: null,
          cancel_access_code_url: null,
          get_access_code_url: null,
          schedule_before_date: '5',
          create_users: false,
          create_users_url: null,
          update_users: false,
          update_users_url: null,
          delete_users: false,
          delete_users_url: null,
          change_users_code: false,
          change_users_code_url: null,
          merge_schedule_invites: false,
          anonymize: false,
        },
        generate_card_number: false,
        disabled: false,
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    body.config = nullify(body.config);

    await GatewaysApi.create(body);

    toast.success(this.props.intl.formatMessage({ id: 'The gateway was created.' }));
    this.props.history.push(`/app/gateways`);
  }

  render() {
    return (
      <GatewayForm
        gateway={this.state.gateway}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default injectIntl(GatewayCreateContainer);
