import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import UserEditComponent from '../../components/Users/edit';
import * as UsersApi from '../../api/users';
import * as DocumentsApi from '../../api/documents';

class UserEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.match.params.userId,
      user: {},
      isLoading: true,
      buildings: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.removeBuilding = this.removeBuilding.bind(this);
    this.fetchBuildings = this.fetchBuildings.bind(this);
    this.approve = this.approve.bind(this);
    this.reject = this.reject.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
  }

  async onSubmit(body) {
    await UsersApi.update(this.state.userId, body);

    toast.success(
      this.props.intl.formatMessage({ id: 'The user has been updated' })
    );
    this.fetchUser();
  }

  async removeBuilding(buildingId) {
    const onConfirmClick = async () => {
      await UsersApi.removeBuilding(this.state.userId, buildingId);

      toast.success(
        this.props.intl.formatMessage({
          id: 'The user was disassociated from the building',
        })
      );
      this.fetchBuildings();
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Remove user from building? This will remove the user from the building completely: if there are multiple associations they will all be removed',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  async approve() {
    const onConfirmClick = async () => {
      await UsersApi.approve(this.state.userId);
      toast.success(
        this.props.intl.formatMessage({
          id: 'The user profile has been approved',
        })
      );
      this.fetchUser();
    };

    confirmAlert({
      message: 'Approve the user profile?',
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  async reject(body) {
    await UsersApi.reject(this.state.userId, body);
    toast.success(
      this.props.intl.formatMessage({
        id: 'The user profile has been rejected',
      })
    );
    this.fetchUser();
  }

  async fetchBuildings() {
    const { data } = await UsersApi.buildings(this.state.userId);

    this.setState({ buildings: data });
  }

  async fetchUser() {
    const { data } = await UsersApi.get(this.state.userId);

    this.setState({
      user: data,
      isLoading: false,
    });
  }

  rotateDocument = (document, direction) => {
    const onConfirmClick = async () => {
      await DocumentsApi.rotateDocument(document.id, direction);

      toast.success(
        this.props.intl.formatMessage({ id: 'The document was rotated' })
      );
      this.fetchUser();
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: `Rotate document ${direction}?`,
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  };

  flopDocument = document => {
    const onConfirmClick = async () => {
      await DocumentsApi.flopDocument(document.id);

      toast.success(
        this.props.intl.formatMessage({ id: 'The document was flipped' })
      );
      this.fetchUser();
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: `Flip document horizontally?`,
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  };

  deleteAccount = async () => {
    const onConfirmClick = async () => {
      await UsersApi.deleteAccount(this.state.userId);
      toast.success(
        this.props.intl.formatMessage({ id: 'The account has been deleted' })
      );
      this.props.history.push('/app/users');
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Are you sure you want to delete the account? This is irreversible',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  };

  render() {
    return (
      <UserEditComponent
        onSubmit={this.onSubmit}
        user={this.state.user}
        buildings={this.state.buildings}
        removeBuilding={this.removeBuilding}
        isLoading={this.state.isLoading}
        approve={this.approve}
        reject={this.reject}
        rekognition={this.rekognition}
        deleteAccount={this.deleteAccount}
        rotateDocument={this.rotateDocument}
        flopDocument={this.flopDocument}
      />
    );
  }

  async componentDidMount() {
    this.fetchBuildings();
    this.fetchUser();
  }
}

export default injectIntl(UserEditContainer);
