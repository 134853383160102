import * as React from 'react';
import * as _ from 'lodash';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import UsersIndex from '../../components/Users/index';
import * as UsersApi from '../../api/users';

class UsersIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        id: '',
        name: '',
        surname: '',
        email: '',
        cellphone: '',
        cpf: '',
        id_number: '',
        building: '',
        company: '',
        facility_code: '',
        key: '',
        active: '',
        admin: '',
        order: 'id desc',
        should_change_password: '__none__',
        profile_completed_at: '__none__',
        profile_approved_at: '__none__',
        profile_rejected_at: '__none__',
        registration_step: '__none__',
        ...qs.parse(props.location.search),
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.forceLogout = this.forceLogout.bind(this);
  }

  async forceLogout(id) {
    const onConfirmClick = async () => {
      await UsersApi.forceLogout(id);

      toast.success(
        this.props.intl.formatMessage({
          id: 'The user was logged out from all devices',
        })
      );
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Log out user from all devices? The user will still be able to log in again. To prevent login mark as inactive',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  async onPageChange({ page, ...where }) {
    const params = _.isEmpty(where) ? this.state.where : where;

    const { data, headers } = await UsersApi.list({
      limit: 10,
      offset: (page - 1) * 10,
      ...params,
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      users: data,
      pages,
      page,
      where: params,
    });

    /*this.props.history.push({
      pathname: '/app/users',
      search: "?" + new URLSearchParams(removeEmptyQs(params)).toString()
    });*/
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <UsersIndex
        users={this.state.users}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        forceLogout={this.forceLogout}
        initialValues={this.state.where}
      />
    );
  }
}

export default injectIntl(UsersIndexContainer);
