import { API_REQUEST, CHANGE_LANGUAGE } from '../constants';

export function req() {
  return {
    payload: 1,
    type: API_REQUEST,
  };
}

export function res() {
  return {
    payload: -1,
    type: API_REQUEST,
  };
}

export function changeLanguage(lang) {
  return {
    payload: lang,
    type: CHANGE_LANGUAGE,
  };
}
