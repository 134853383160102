import request from './index';

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/tos',
  });
}

export function create(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/admin/tos',
  });
}

export function destroy(id) {
  return request({
    method: 'DELETE',
    path: `/api/admin/tos/${id}`,
  });
}
