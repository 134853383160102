import axios from 'axios';
import * as _ from 'lodash';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function listUsers() {
  return request({
    method: 'GET',
    path: '/api/admin/desktop-app/name-user',
  });
}

export function get(params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/desktop-app/system-history`,
  });
}
