import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import MomentUtils from '@date-io/moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import { Input, Select } from '../Shared/BsInput';
import { countBy } from 'lodash';

const Pie = ({ title, series }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: title,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        },
      },
    },
    series: [
      {
        name: 'Default',
        colorByPoint: true,
        data: series,
      },
    ],
  };

  return (
    <Box p={2}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};

const RenderCharts = ({ data }) => {
  return (
    <Box>
      <Pie
        title={`Created users in period: ${
          data.total.complete + data.total.incomplete
        }`}
        series={[
          { name: 'Registration complete', y: data.total.complete },
          { name: 'Registration incomplete', y: data.total.incomplete },
        ]}
      />
      <hr />
      <Pie
        title={'Registration reason'}
        series={[
          { name: 'Invited by somebody else', y: data.source.invited },
          { name: 'Organic', y: data.source.organic },
        ]}
      />
      <hr />
      <Pie
        title={'Users invited by somebody else: registration status'}
        series={[
          { name: 'Registration complete', y: data.invited.complete },
          { name: 'Registration incomplete', y: data.invited.incomplete },
        ]}
      />
      <hr />
      <Pie
        title={'Users invited by somebody else: invitation reason'}
        series={[
          {
            name: 'Invited as part of a building',
            y: data.invitation_reason.employees,
          },
          { name: 'Invited to a meeting', y: data.invitation_reason.guests },
        ]}
      />
      <hr />
      <Pie
        title="Incomplete users pending registration step"
        series={data.registration_steps.map(step => {
          return {
            name: step.registration_step,
            y: step.count,
          };
        })}
      />
    </Box>
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <DateTimePicker
            value={values.date_from}
            onChange={value => {
              if (value) {
                setFieldValue('date_from', value.format());
              } else {
                setFieldValue('date_from', null);
              }
            }}
            ampm={false}
            fullWidth
            clearable
            autoOk
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="Start date" />}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DateTimePicker
            value={values.date_to}
            onChange={value => {
              if (value) {
                setFieldValue('date_to', value.format());
              } else {
                setFieldValue('date_to', null);
              }
            }}
            ampm={false}
            fullWidth
            clearable
            autoOk
            margin="dense"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="End date" />}
          />
        </Grid>
        <Grid item xs={6} md={3} className="mt-3">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, data }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-bar-chart"></i>{' '}
          <FormattedMessage id="Registration stats" />
        </Typography>
      }
      content={
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <Formik
              initialValues={{
                date_from: moment().subtract(1, 'month'),
                date_to: moment(),
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={RenderForm}
            />
            <hr />
            {!!data && <RenderCharts data={data} />}
          </div>
        </MuiPickersUtilsProvider>
      }
    />
  );
};
