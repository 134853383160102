import * as React from 'react';
import Modal from 'react-bootstrap4-modal';
import { Select } from '../Shared/BsInput';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    LinearProgress,
} from '@material-ui/core';
import {
    BUILDING_MANAGER,
    EMPLOYEE,
    HOST_EMPLOYEE,
    SECURITY,
    COMPANY_MANAGER,
    COMPANY_SECRETARY
} from '../../constants';
import { toast } from 'react-toastify';

export const RenderModalEditRole = ({
    data: { userbuilding, isEditModalOpen },
    setData,
    onEdit,
    companies,
  }) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = React.useState(false);
    const [newRole, setNewRole] = React.useState('');
    const [company, setCompany] = React.useState('');
  
    React.useEffect(() => {
      if(company === '' && userbuilding.Company?.id){
        setCompany(userbuilding.Company?.id)
      }
    }, [userbuilding]);
  
    const handleSave = React.useCallback(async () => {
      if (!newRole) {
        toast.info(formatMessage({ id: 'Fill new role field'}));
        return;
      }
  
      try {
        setLoading(true);
        await onEdit(userbuilding.id, newRole, company);
        setData((prevState) => ({ ...prevState, isEditModalOpen: false }));
        setNewRole('');
        window.location.reload();
        toast.success(formatMessage({ id: 'User has been edited'}));
      } catch ({ response: { data: { message } } }) {
        toast.error(message);
      } finally {
        setLoading(false);
      }
    }, [onEdit, setData, userbuilding.id, newRole]);
  
    const handleChange = React.useCallback(({ target: { value } }) => setNewRole(value), []);
    const handleChangeCompany = React.useCallback(({ target: { value } }) => {
      setCompany(value);
      setNewRole('')
    }, []);
    const handleBack = React.useCallback(() => {
      setData((prevState) => ({ ...prevState, isEditModalOpen: false }));
      setNewRole('');
      setCompany('');
    }, [newRole, isEditModalOpen]);
  
    return (
      <Modal visible={isEditModalOpen} onClose={() => setData((prevState) => ({ ...prevState, isEditModalOpen: false }))}>
        <div className="modal-header">
          <h5 className="modal-title">
            {formatMessage({ id: 'Select a new role to the user ' })}
            {` ${userbuilding?.User?.name} ${userbuilding?.User?.surname}`}
          </h5>
        </div>
        <div className="modal-body">
          <div>{formatMessage({ id: 'Current role:' })} {userbuilding.role}</div>
          {!!companies.length && (
            <Select
              value={company}
              onChange={handleChangeCompany}
              name="company_id"
              label={<FormattedMessage id="Company" />}
              options={[{ value: '', label: '-' }].concat(
                companies.map(c => ({ value: c.id, label: c.name }))
              )}
            />
          )}
          <Select
            style={{ paddingTop: '8px' }}
            value={newRole}
            onChange={handleChange}
            name="role"
            label={formatMessage({ id: 'New role:'})}
            options={[
              ...(!company
                ? [{ value: BUILDING_MANAGER, label: BUILDING_MANAGER }]
                : []),
              ...(company
                ? [{ value: COMPANY_MANAGER, label: COMPANY_MANAGER }]
                : []),
              ...(company
                ? [{ value: COMPANY_SECRETARY, label: COMPANY_SECRETARY }]
                : []),
            ].concat([
              { value: HOST_EMPLOYEE, label: HOST_EMPLOYEE },
              { value: EMPLOYEE, label: EMPLOYEE },
              ...(!company
                ? [{ value: SECURITY, label: SECURITY }]
                : []),
            ])}
          />
          {loading && (<LinearProgress style={{ marginTop: 15 }} />)} 
        </div>
        <div className="modal-footer">
          <Button
            onClick={handleBack}
            variant="text"
            color="primary"
            disabled={loading}
          >
            <FormattedMessage id="Return" />
          </Button>
          <Button
            onClick={handleSave}
            variant="text"
            color="secondary"
            disabled={loading}
          >
            <FormattedMessage id="Save" />
          </Button>
        </div>
      </Modal>
    );
  };
  
export const RenderModalAddRole = ({
    data: { userbuilding, isAddModalOpen },
    setData,
    onAdd,
    companies
  }) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = React.useState(false);
    const [role, setRole] = React.useState('');
    const [company, setCompany] = React.useState('');
  
    const handleSave = React.useCallback(async () => {
      if (!role) {
        toast.info(formatMessage({ id: 'Fill role field'}));
        return;
      }
  
      try {
        setLoading(true);
        await onAdd(userbuilding.User.id, role, company);
        setData((prevState) => ({ ...prevState, isAddModalOpen: false }));
        setRole('');
        toast.success(formatMessage({ id: 'User has been added'}));
        window.location.reload();
      } catch ({ response: { data: { message } } }) {
        toast.error(message);
      } finally {
        setLoading(false);
      }
    }, [onAdd, setData, userbuilding.id, role]);
  
    const handleChange = React.useCallback(({ target: { value } }) => setRole(value), []);
    const handleChangeCompany = React.useCallback(({ target: { value } }) => {
      setCompany(value);
      setRole('')
    }, []);
  
    const handleBack = React.useCallback(() => {
      setData((prevState) => ({ ...prevState, isAddModalOpen: false }));
      setRole('');
    }, [role, isAddModalOpen]);
  
    return (
      <Modal visible={isAddModalOpen} onClose={() => setData((prevState) => ({ ...prevState, isAddModalOpen: false }))}>
        <div className="modal-header">
          <h5 className="modal-title">
            {formatMessage({ id: 'Select a role to the user ' })}
            {` ${userbuilding?.User?.name} ${userbuilding?.User?.surname}`}
          </h5>
        </div>
        <div className="modal-body">
          {!!companies.length && (
            <Select
              value={company}
              onChange={handleChangeCompany}
              name="company_id"
              label={<FormattedMessage id="Company" />}
              options={[{ value: '', label: '-' }].concat(
                companies.map(c => ({ value: c.id, label: c.name }))
              )}
            />
          )}
          <Select
            style={{ paddingTop: '8px' }}
            value={role}
            onChange={handleChange}
            name="role"
            label={formatMessage({ id: 'Role:'})}
            options={[
              ...(!company
                ? [{ value: BUILDING_MANAGER, label: BUILDING_MANAGER }]
                : []),
              ...(company
                ? [{ value: COMPANY_MANAGER, label: COMPANY_MANAGER }]
                : []),
              ...(company
                ? [{ value: COMPANY_SECRETARY, label: COMPANY_SECRETARY }]
                : []),
            ].concat([
              { value: HOST_EMPLOYEE, label: HOST_EMPLOYEE },
              { value: EMPLOYEE, label: EMPLOYEE },
              ...(!company
                ? [{ value: SECURITY, label: SECURITY }]
                : []),
            ])}
          />
          {loading && (<LinearProgress style={{ marginTop: 15 }} />)} 
        </div>
        <div className="modal-footer">
          <Button
            onClick={handleBack}
            variant="text"
            color="primary"
            disabled={loading}
          >
            <FormattedMessage id="Return" />
          </Button>
          <Button
            onClick={handleSave}
            variant="text"
            color="secondary"
            disabled={loading}
          >
            <FormattedMessage id="Save" />
          </Button>
        </div>
      </Modal>
    );
  };