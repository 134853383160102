import * as React from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import * as _ from 'lodash';
import { Input, MaskedInput, Select, Checkbox } from '../Shared/BsInput';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage } from 'react-intl';
import { EditUserSchema } from '../../validation-schemas';

const RenderForm = ({
  buildings,
  removeBuilding,
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => {
  let label = 'Passport';

  if (values.id_type === 'rg') {
    label = <span style={{ textTransform: 'uppercase' }}>{'RG'}</span>;
  } else if (values.id_type === 'rne') {
    label = <span style={{ textTransform: 'uppercase' }}>{'RNE'}</span>;
  } else if (values.id_type === 'hab') {
    label = 'Habilitaçao';
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={<FormattedMessage id="Surname" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.nationality}
            onChange={handleChange}
            name="nationality"
            label={<FormattedMessage id="Nationality" />}
            errors={errors}
            helperText={<FormattedMessage id="2 characters ISO code" />}
          />
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={<FormattedMessage id="Email" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.cellphone}
            onChange={handleChange}
            name="cellphone"
            label={<FormattedMessage id="Cellphone" />}
            errors={errors}
          />
          <DateTimePicker
            value={values.birthdate}
            disableFuture
            onChange={value => {
              setFieldValue('birthdate', value);
            }}
            ampm={false}
            fullWidth
            margin="normal"
            format="DD-MM-YYYY"
            views={['year', 'month', 'date']}
            label={<FormattedMessage id="Birthdate" />}
          />
          <Input
            type="text"
            value={values.company}
            onChange={handleChange}
            name="company"
            label={<FormattedMessage id="Company" />}
            errors={errors}
          />
          <Input
            type="text"
            value={values.company_role}
            onChange={handleChange}
            name="company_role"
            label={<FormattedMessage id="Role in the company" />}
            errors={errors}
          />

          <Select
            value={values.id_type}
            onChange={handleChange}
            name="id_type"
            label={<FormattedMessage id="ID Type" />}
            errors={errors}
            options={[
              {
                value: 'rg',
                label: (
                  <span style={{ textTransform: 'uppercase' }}>{'RG'}</span>
                ),
              },
              {
                value: 'rne',
                label: (
                  <span style={{ textTransform: 'uppercase' }}>{'RNE'}</span>
                ),
              },
              { value: 'hab', label: 'Habilitaçao' },
              { value: 'passport', label: 'Passport' },
            ]}
            margin="dense"
          />
          <Input
            type="text"
            value={values.id_number}
            onChange={handleChange}
            name="id_number"
            label={<FormattedMessage id="abe" />}
            errors={errors}
          />

          <MaskedInput
            type="text"
            placeholder="xxx.xxx.xxx-xx"
            value={values.cpf}
            onChange={handleChange}
            name="cpf"
            id="cpf-input"
            label={<FormattedMessage id="CPF number" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Alert severity="error" elevation={3}>
            <FormattedMessage
              id="Manually changing the registration step, email verified or cellphone verified field could potentially break the user
            if the changes are not consistent between each other."
            />
            <br />
            <FormattedMessage id="To prevent a user from logging in, simply uncheck 'User is active'" />
          </Alert>
          <br />
          <Select
            value={values.registration_step}
            onChange={handleChange}
            name="registration_step"
            label={<FormattedMessage id="Registration step" />}
            errors={errors}
            options={[
              { value: '', label: <FormattedMessage id="None" /> },
              {
                value: 'CELLPHONE_VERIFICATION',
                label: <FormattedMessage id="Cellphone verification" />,
              },
              {
                value: 'EMAIL_VERIFICATION',
                label: <FormattedMessage id="Email verification" />,
              },
              {
                value: 'BASIC_INFORMATION',
                label: <FormattedMessage id="Basic information" />,
              },
              {
                value: 'PROFILE_PICTURE',
                label: <FormattedMessage id="Profile picture" />,
              },
              {
                value: 'DOCUMENTS',
                label: <FormattedMessage id="Documents" />,
              },
              {
                value: 'CONFIRM_INFORMATION',
                label: <FormattedMessage id="Confirm information" />,
              },
            ]}
            margin="dense"
            disabled={!values.registration_step}
          />
          <br />
          <div>
            <Checkbox
              value={values.active}
              onChange={handleChange}
              name="active"
              errors={errors}
            />{' '}
            <FormattedMessage id="User is active" />
          </div>
          <div>
            <Checkbox
              value={values.admin}
              onChange={handleChange}
              name="admin"
              errors={errors}
            />{' '}
            <FormattedMessage id="User is admin" />
          </div>
          <div>
            <Checkbox
              value={values.should_change_password}
              onChange={handleChange}
              name="should_change_password"
              errors={errors}
            />{' '}
            <FormattedMessage id="User should change password" />
          </div>
          <div>
            <Checkbox
              value={!!values.email_verified_at}
              onChange={e => {
                if (e.target.checked) {
                  setFieldValue('email_verified_at', moment().toISOString());
                } else {
                  setFieldValue('email_verified_at', null);
                }
              }}
              name="email_verified_at"
              errors={errors}
            />{' '}
            <FormattedMessage id="Email verified" />
          </div>
          <div>
            <Checkbox
              value={!!values.cellphone_verified_at}
              onChange={e => {
                if (e.target.checked) {
                  setFieldValue(
                    'cellphone_verified_at',
                    moment().toISOString()
                  );
                } else {
                  setFieldValue('cellphone_verified_at', null);
                }
              }}
              name="cellphone_verified_at"
              errors={errors}
            />{' '}
            <FormattedMessage id="Cellphone verified" />
          </div>
          {!!buildings.length && (
            <Table size="small" style={{marginTop: 10}}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Company" />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.map((b, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{b.id}</TableCell>
                      <TableCell>{b.name}</TableCell>
                      <TableCell>{b.Company ? b.Company.name : '-'}</TableCell>
                      <TableCell>
                        <Button
                          color="secondary"
                          size="small"
                          style={{ marginBottom: '5px' }}
                          fullWidth
                          onClick={() => removeBuilding(b.id)}
                        >
                          <FormattedMessage id="Remove user from building" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item xs={12}>
          <hr />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, user, buildings, removeBuilding, isLoading }) => {
  if (isLoading) {
    return null;
  }

  return (
    <Paper style={{ padding: '10px' }}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={user}
          enableReinitialize={true}
          validationSchema={EditUserSchema}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              const data = [
                'name',
                'surname',
                'email',
                'cellphone',
                'company',
                'company_role',
                'birthdate',
                'id_type',
                'id_number',
                'cpf',
                'active',
                'admin',
                'should_change_password',
                'email_verified_at',
                'cellphone_verified_at',
                'nationality',
                'registration_step',
              ].reduce((acc, key) => {
                if (_.isString(values[key]) && values[key]) {
                  acc[key] = values[key];
                } else if (values[key] !== null) {
                  acc[key] = values[key];
                }

                return acc;
              }, {});

              if (data.birthdate) {
                data.birthdate = moment(data.birthdate).format('YYYY-MM-DD');
              }

              await onSubmit(data);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderForm buildings={buildings} removeBuilding={removeBuilding} {...p} />}
        />
      </MuiPickersUtilsProvider>
    </Paper>
  );
};
