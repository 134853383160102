import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import ContainerCard from '../Shared/container-card';
import { Select } from '../Shared/BsInput';
import { toast } from 'react-toastify';

const styles = makeStyles(theme => ({
  tc: {
    paddingRight: '4 !important',
    paddingLeft: '5 !important',
  },
  legend: {
    border: '1px solid #333',
    width: '10px',
    height: '10px',
    display: 'inline-block',
  },
}));

const RenderTable = ({ users }) => {
  const classes = styles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Surname" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Birthdate" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Email" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Cellphone" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Identification" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Cpf" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Company" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Company role" />
          </TableCell>
          <TableCell className={classes.tc}>
            <FormattedMessage id="Facility-Access code" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user, i) => {
          const style = {
            color: !user.valid ? 'white' : 'default',
          };

          return (
            <TableRow
              key={i}
              style={{
                backgroundColor: !user.valid
                  ? '#dc3545'
                  : user.registered
                  ? '#28a74542'
                  : 'default',
                marginBottom: user.errorRow ? '20px' : '0px',
              }}
            >
              <TableCell style={style} className={classes.tc}>
                {user.name}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.surname}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.birthdate}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.email}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.cellphone}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.id_type}: {user.id_number}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.cpf}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.company}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.company_role}
              </TableCell>
              <TableCell style={style} className={classes.tc}>
                {user.facility_code}-{user.access_code}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  buildings,
  companies,
  fetchCompanies,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Select
            value={values.building_id}
            onChange={e => {
              setFieldValue('company_id', null);
              fetchCompanies(e.target.value);

              handleChange(e);
            }}
            name="building_id"
            label={<FormattedMessage id="Building" />}
            options={buildings.map(b => ({ value: b.id, label: b.name }))}
            errors={errors}
          />
        </Grid>
        {companies && !!companies.length && (
          <Grid item xs={12} md={4}>
            <Select
              value={values.company_id || 'none'}
              onChange={handleChange}
              name="company_id"
              label={<FormattedMessage id="Company" />}
              options={
                [
                  { 
                    value: 'none', 
                    label: <FormattedMessage id="None" /> 
                  },
                  ...companies.map(b => (
                  { 
                    value: b.id, 
                    label: b.name 
                  }))
                ]}
              errors={errors}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <Button
            style={{ marginTop: '16px' }}
            component="label"
            color="default"
            variant="outlined"
            fullWidth
            disableRipple
            disableFocusRipple
          >
            {!values.file && (
              <>
                <FormattedMessage id="Select your file" />
              </>
            )}
            {values.file && <>File: {values.file.name}</>}
            <input
              type="file"
              accept=".csv"
              onChange={e => {
                setFieldValue('file', e.target.files[0]);
              }}
              style={{ display: 'none' }}
            />
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Pre-process file" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default props => {
  const {
    onSubmit,
    buildings,
    companies,
    users,
    onConfirm,
    fetchCompanies,
    restart,
    error,
  } = props;
  const classes = styles();

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-building-o"></i>{' '}
          <FormattedMessage id="Import users" />
        </Typography>
      }
      content={
        <>
          <div>
            <Button
              type="button"
              variant="outlined"
              onClick={restart}
              className="pull-right"
            >
              <i className="fa fa-refresh"></i>
            </Button>
          </div>
          {!users && (
            <Formik
              initialValues={{
                building_id: '',
                company_id: '',
                file: null,
              }}
              validate={values => {
                const errors = {};

                if (!values.building_id) {
                  errors.building_id = 'Required';
                }

                return errors;
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  if(values?.company_id === 'none') values.company_id = null;
                  await onSubmit({ ...values });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => (
                <RenderForm
                  {...p}
                  {...{ buildings, companies, fetchCompanies }}
                />
              )}
            />
          )}
          {users && !!users.length && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div className="text-right">
                  <Typography component="span">
                    <FormattedMessage id="Invalid row, will be ignored" />
                  </Typography>
                  &nbsp;
                  <div
                    className={classes.legend}
                    style={{ backgroundColor: '#dc3545', color: 'white' }}
                  ></div>
                </div>
                <div className="text-right">
                  <Typography component="span">
                    <FormattedMessage id="User already registered" />
                  </Typography>
                  &nbsp;
                  <div
                    className={classes.legend}
                    style={{ backgroundColor: '#28a74542' }}
                  ></div>
                </div>
                <div className="text-right">
                  <Typography component="span">
                    <FormattedMessage id="User not registered, will be created" />
                  </Typography>
                  &nbsp;
                  <div className={classes.legend}></div>
                </div>
                <hr />
                {error && (
                  <div className="alert alert-danger">
                    <FormattedMessage id="The following users could not be imported" />
                  </div>
                )}
                <RenderTable {...{ users }} />
                <hr />
                {!error && (
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={onConfirm}
                    >
                      <FormattedMessage id="Import the file" />
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
};
