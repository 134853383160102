import * as React from 'react';
import isBoolean from 'lodash/isBoolean';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Checkbox } from '../Shared/BsInput';
import { copyToClipboard } from '../../helpers/clipboard';
import { Tooltip } from '@material-ui/core';
import { toast } from 'react-toastify';
import { LANGUAGE_DEFAULT } from '../../constants';

const FRONT_URL = process.env.REACT_APP_FRONT_URL;

const Tr = ({ label, value }) => {
  return (
    <TableRow>
      <TableCell>
        <b>{label}</b>
      </TableCell>
      <TableCell align="right">
        {isBoolean(value) ? (value ? 'Yes' : 'No') : value}
      </TableCell>
    </TableRow>
  );
};

const BirthdateTr = ({ value }) => {
  const [showBr, setShowBr] = React.useState(true);
  const [showTooltipo, setShowTooltipo] = React.useState(false);
  const date = showBr
    ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
    : value;

  const onClickDate = () => {
    copyToClipboard(date);
    setShowTooltipo(true);
  };

  return (
    <TableRow>
      <TableCell>
        <b>
          <FormattedMessage id="Birthdate" />
        </b>
      </TableCell>
      <TableCell
        align="right"
        onClick={onClickDate}
        onMouseLeave={() => setShowTooltipo(false)}
      >
        {!!value && date}
        <Tooltip
          title="Copied to clipboard"
          placement="top"
          open={showTooltipo}
        >
          <b
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={() => setShowBr(!showBr)}
          >
            {showBr ? 'EN' : 'PT'}
          </b>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

const FaceMismatch = () => {
  return (
    <Alert severity="error" elevation={3}>
      <FormattedMessage id="Profile picture does not match document" />
    </Alert>
  );
};

const InformationMismatch = ({ event }) => {
  return (
    <Alert severity="error" elevation={3}>
      <FormattedMessage id="The following fields do not match" />:
      {Object.keys(event.meta.unmatched)
        .filter(key => event.meta.unmatched[key])
        .map((u, i) => {
          return (
            <div key={i}>
              - <FormattedMessage id={u} />
            </div>
          );
        })}
    </Alert>
  );
};

const CpfMismatch = ({ user }) => {
  if (
    !user.profile_completed_at ||
    user.profile_approved_at ||
    (!user.profile_completed_at && user.profile_rejected_at)
  ) {
    return null;
  }

  const mismatch = user.Events.find(
    e => e.subtype === 'SMS_FIRE_CPF_CHECK_FAILURE'
  );

  if (!mismatch) {
    return null;
  }

  return (
    <Alert severity="error" elevation={3}>
      <FormattedMessage id="The CPF did not match in SmsFire" />
    </Alert>
  );
};

const AutomaticApprovalFailed = ({ user }) => {
  if (
    !user.profile_completed_at ||
    user.profile_approved_at ||
    (!user.profile_completed_at && user.profile_rejected_at)
  ) {
    return null;
  }

  const info = user.Events.find(
    e => e.subtype === 'REKOGNITION_COMPARE_INFORMATION_FAILURE'
  );
  const face = user.Events.find(
    e => e.subtype === 'REKOGNITION_COMPARE_FACES_FAILURE'
  );

  if (face && info) {
    if (moment(face.created_at).isAfter(moment(info.created_at))) {
      return <FaceMismatch />;
    } else {
      return <InformationMismatch event={info} />;
    }
  }

  if (face) {
    return <FaceMismatch />;
  }

  if (info) {
    return <InformationMismatch event={info} />;
  }

  return null;
};

const DocumentTools = ({ document, rotateDocument, flopDocument }) => {
  return (
    <div style={{ marginTop: 8 }}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => rotateDocument(document, 'left')}
        style={{ marginRight: 12 }}
      >
        <i className="fa fa-rotate-left"></i>&nbsp;
        <FormattedMessage id="Rotate left" />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => rotateDocument(document, 'right')}
        style={{ marginRight: 12 }}
      >
        <i className="fa fa-rotate-right"></i>
        &nbsp;
        <FormattedMessage id="Rotate right" />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => flopDocument(document)}
      >
        <i className="fa fa-arrows-h"></i>
        &nbsp;
        <FormattedMessage id="Flip horizontally" />
      </Button>
    </div>
  );
};

export default ({
  buildings,
  user,
  removeBuilding,
  deleteAccount,
  rotateDocument,
  flopDocument,
}) => {
  const [confirmed, setConfirm] = React.useState(false);
  const idback = user.Documents.find(d => d.type === 'ID_BACK');
  const idfront = user.Documents.find(d => d.type === 'ID_FRONT');
  const intl = useIntl();

  return (
    <Paper style={{ padding: '10px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <CpfMismatch user={user} />
          <AutomaticApprovalFailed user={user} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Table size="small">
            <TableBody>
              <Tr label={<FormattedMessage id="ID" />} value={user.id} />
              <Tr label={<FormattedMessage id="Name" />} value={user.name} />
              <Tr
                label={<FormattedMessage id="Surname" />}
                value={user.surname}
              />
              <BirthdateTr value={user.birthdate} />
              <Tr label={<FormattedMessage id="Email" />} value={user.email} />
              <Tr
                label={<FormattedMessage id="Cellphone" />}
                value={user.cellphone}
              />
              <Tr
                label={<FormattedMessage id="ID Type" />}
                value={user.id_type}
              />
              <Tr
                label={<FormattedMessage id="Id Number" />}
                value={user.id_number}
              />
              <Tr label={<FormattedMessage id="CPF" />} value={user.cpf} />
              <Tr
                label={<FormattedMessage id="Company" />}
                value={user.company}
              />
              <Tr
                label={<FormattedMessage id="Company role" />}
                value={user.company_role}
              />
              <Tr
                label={<FormattedMessage id="Nationality" />}
                value={user.nationality}
              />
              <Tr
                label={<FormattedMessage id="Language" />}
                value={(user.language || LANGUAGE_DEFAULT).toUpperCase()}
              />
              <Tr
                label={<FormattedMessage id="Registration step" />}
                value={user.registration_step}
              />
              <Tr
                label={<FormattedMessage id="Active" />}
                value={user.active}
              />
              <Tr
                label={<FormattedMessage id="Is admin" />}
                value={user.admin}
              />
              <Tr
                label={<FormattedMessage id="Should change password" />}
                value={user.should_change_password}
              />
              <Tr
                label={<FormattedMessage id="Profile complete at" />}
                value={
                  user.profile_completed_at
                    ? moment(user.profile_completed_at).format(
                        'DD-MM-YYYY HH:mm'
                      )
                    : '-'
                }
              />
              <Tr
                label={<FormattedMessage id="Profile approved at" />}
                value={
                  user.profile_approved_at
                    ? moment(user.profile_approved_at).format(
                        'DD-MM-YYYY HH:mm'
                      )
                    : '-'
                }
              />
              <Tr
                label={<FormattedMessage id="Profile rejected at" />}
                value={
                  user.profile_rejected_at
                    ? moment(user.profile_rejected_at).format(
                        'DD-MM-YYYY HH:mm'
                      )
                    : '-'
                }
              />
              <Tr
                label={<FormattedMessage id="Invited by user" />}
                value={
                  user.invited_by_user_id ? (
                    <Link
                      to={`/app/users/${user.invited_by_user_id}/edit`}
                      target="_blank"
                    >
                      {user.invited_by_user_id}
                    </Link>
                  ) : null
                }
              />
              <Tr
                label={<FormattedMessage id="Cellphone verified" />}
                value={
                  user.cellphone_verified_at
                    ? moment(user.cellphone_verified_at).format(
                        'DD-MM-YYYY HH:mm'
                      )
                    : null
                }
              />
              <Tr
                label={<FormattedMessage id="Email verified" />}
                value={
                  user.email_verified_at
                    ? moment(user.email_verified_at).format('DD-MM-YYYY HH:mm')
                    : null
                }
              />
              <Tr
                label={<FormattedMessage id="Created at" />}
                value={moment(user.createdAt).format('DD-MM-YYYY HH:mm')}
              />
              <Tr
                label={<FormattedMessage id="Updated at" />}
                value={moment(user.updatedAt).format('DD-MM-YYYY HH:mm')}
              />
              <Tr
                label={<FormattedMessage id="Deleted at" />}
                value={
                  user.deleted_at
                    ? moment(user.deleted_at).format('DD-MM-YYYY HH:mm')
                    : null
                }
              />
              <Tr
                label={<FormattedMessage id="Long token" />}
                value={user.long_token}
              />
              <Tr
                label={<FormattedMessage id="SMS code" />}
                value={user.CellphoneCode ? user.CellphoneCode.code : null}
              />
              <Tr
                label={<FormattedMessage id="Last device code" />}
                value={
                  <>
                    <b>{user?.last_device_code?.code || '-'}</b> -
                    {user?.last_device_code?.code_valid_until && (
                      <FormattedMessage
                        id="Valid until {date}"
                        values={{
                          date: moment(
                            user.last_device_code.code_valid_until
                          ).format('DD-MM-YYYY HH:mm'),
                        }}
                      />
                    )}
                  </>
                }
              />
            </TableBody>
          </Table>
          <hr />
          <div>
            <Typography variant="subtitle1">
              <FormattedMessage id="Login as user" />
            </Typography>
            <a href={`${FRONT_URL}?token=${user.jwt}`} target="_blank">
              <FormattedMessage id="Click to login as the user" />
            </a>
          </div>
          <div className="mt-2">
            <Button
              color="primary"
              type="button"
              size="small"
              onClick={() => {
                copyToClipboard(user.jwt);
                toast.success(
                  intl.formatMessage({ id: 'Token copied to clipboard' })
                );
              }}
              variant="outlined"
            >
              <FormattedMessage id="Click to copy JWT token" />
            </Button>
          </div>
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <Checkbox
              value={confirmed}
              checked={confirmed}
              onChange={e => setConfirm(e.target.checked)}
            />{' '}
            <Typography component="span">
              <FormattedMessage id="Delete this user's account" />
            </Typography>
            <div>
              {confirmed && (
                <Box textAlign="center">
                  <Alert severity="error" elevation={3}>
                    <FormattedMessage id="Deleting the account is irreversible. All the user information will be lost" />
                  </Alert>
                  <br />
                  <Button
                    color="secondary"
                    type="button"
                    onClick={deleteAccount}
                    variant="outlined"
                  >
                    <FormattedMessage id="I understand, delete the account" />
                  </Button>
                </Box>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <div className="text-center">
              <img
                alt=""
                style={{
                  maxWidth: '80%',
                  maxHeight: '200px',
                  border: '2px solid white',
                }}
                src={user.profile_picture || '/profile-placeholder.png'}
              />
            </div>
            <br />
            {idfront && (
              <div className="text-center">
                <a href={idfront.public_url}>
                  <img
                    alt=""
                    src={`${idfront.public_url}?cachebust=${Date.now()}`}
                    style={{ maxWidth: '70%' }}
                  />
                </a>
                <DocumentTools
                  document={idfront}
                  rotateDocument={rotateDocument}
                  flopDocument={flopDocument}
                />
              </div>
            )}
            <br />
            {idback && (
              <div className="text-center">
                <a href={idback.public_url}>
                  <img
                    alt=""
                    src={`${idback.public_url}?cachebust=${Date.now()}`}
                    style={{ maxWidth: '70%' }}
                  />
                </a>
                <DocumentTools
                  document={idback}
                  rotateDocument={rotateDocument}
                  flopDocument={flopDocument}
                />
              </div>
            )}
          </div>
          <hr />
          {!buildings.length && (
            <div className="alert alert-info text-center">
              <FormattedMessage id="The user is not associated to any building" />
            </div>
          )}
          {!!buildings.length && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ID" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Name" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Address" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Company" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="Keys" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {buildings.map((b, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{b.id}</TableCell>
                      <TableCell>{b.name}</TableCell>
                      <TableCell>{b.address}</TableCell>
                      <TableCell>{b.Company ? b.Company.name : '-'}</TableCell>
                      <TableCell style={{ maxWidth: 100, wordBreak: 'normal' }}>
                        {b.keys ? b.keys.toString().replaceAll(',', ' ') : '-'}
                      </TableCell>
                      {/* <TableCell>
                        <Button
                          color="secondary"
                          size="small"
                          style={{ marginBottom: '5px' }}
                          fullWidth
                          onClick={() => removeBuilding(b.id)}
                        >
                          <FormattedMessage id="Remove user from building" />
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          <hr />
        </Grid>
      </Grid>
    </Paper>
  );
};
