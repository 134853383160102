import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import TosForm from '../../components/Tos/form';
import * as TosApi from '../../api/tos';

class CreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      record: {
        type: 'TOS',
        description: '',
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await TosApi.create(body);

    toast.success(
      this.props.intl.formatMessage({ id: 'The revision was created' })
    );
    this.props.history.push('/app/tos');
  }

  render() {
    return <TosForm record={this.state.record} onSubmit={this.onSubmit} />;
  }
}

export default injectIntl(CreateContainer);
