import axios from 'axios';
import * as _ from 'lodash';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/gateways',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/admin/gateways/${id}`,
  });
}

export function create(body) {
  return request({
    body,
    method: 'POST',
    path: '/api/admin/gateways',
  });
}

export function update(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/admin/gateways/${id}`,
  });
}

export function disable(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/gateways/disable/${id}`,
  });
}

export function enable(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/gateways/enable/${id}`,
  });
}
