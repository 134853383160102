import * as React from 'react';
import {
  AppBar,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import BasicInformation from './basic-information';
import UserForm from './form';
import UserReviews from './review';
import CreateReviewDialog from './create-review-dialog';
import UserEvents from './events';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography component="div">{children}</Typography>
        </div>
      )}
    </div>
  );
}

export default ({
  buildings,
  user,
  isLoading,
  removeBuilding,
  rotateDocument,
  flopDocument,
  onSubmit,
  approve,
  reject,
  deleteAccount,
}) => {
  const [value, setValue] = React.useState(0);
  const [reviewDialogOpened, setReviewDialogOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <div></div>;
  }
  return (
    <>
      <CreateReviewDialog
        onSubmit={reject}
        handleClose={() => setReviewDialogOpen(false)}
        open={reviewDialogOpened}
      />
      <Paper
        style={{ padding: '10px', backgroundColor: '#37474F' }}
        elevation={3}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ textAlign: 'right' }}>
              {!user.profile_approved_at && (
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  onClick={approve}
                  size="small"
                >
                  <FormattedMessage id="Approve profile" />
                </Button>
              )}
              &nbsp;
              {
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  onClick={() => setReviewDialogOpen(true)}
                  size="small"
                >
                  <FormattedMessage id="Reject profile" />
                </Button>
              }
            </div>
          </Grid>
        </Grid>
      </Paper>
      <AppBar position="static" color="default">
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label={<FormattedMessage id="Basic information" />} />
          <Tab label={<FormattedMessage id="Modify" />} />
          <Tab label={<FormattedMessage id="Profile Review" />} />
          <Tab label={<FormattedMessage id="Events" />} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <BasicInformation
          user={user}
          buildings={buildings}
          removeBuilding={removeBuilding}
          deleteAccount={deleteAccount}
          rotateDocument={rotateDocument}
          flopDocument={flopDocument}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserForm user={user} buildings={buildings} removeBuilding={removeBuilding} onSubmit={onSubmit} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserReviews user={user} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UserEvents user={user} />
      </TabPanel>
    </>
  );
};
