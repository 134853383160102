import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import BuildingAddUser from '../../components/Buildings/add-user';
import * as BuildingsApi from '../../api/buildings';

class AddUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      buildingId: this.props.match.params.buildingId,
      email: this.props.match.params.email,
      building: null,
      isLoading: true,
      user: null,
      searched: false,
      companies: [],
    };
  }

  onSubmit = async body => {
    const searchUsers = await BuildingsApi.searchUserByKey(this.state.buildingId, { excludedId: body.id, key: body.AccessCode.key });
    
    if(searchUsers?.data?.exist)  return {error: true, info: searchUsers.data};

    const request = await BuildingsApi.addUser(this.state.buildingId, body);

    toast.success(
      this.props.intl.formatMessage({
        id: 'The user has been added to the building',
      })
    );
    this.props.history.push(`/app/buildings/${this.state.buildingId}/users`);
  };

  onUnlinkKeys = async (userToUnlink) => {
    const request = await BuildingsApi.removeRoles(this.state.buildingId, userToUnlink);

    toast.success(
      this.props.intl.formatMessage({
        id: 'Unlinked user',
      })
    );
  };

  searchUser = async body => {
    const { data } = await BuildingsApi.searchUser(this.state.buildingId, body);

    this.setState({
      user: data[0] || null,
      searched: true,
      email: body.email,
    });
  };

  render() {
    return (
      <BuildingAddUser
        building={this.state.building}
        companies={this.state.companies}
        user={this.state.user}
        searched={this.state.searched}
        isLoading={this.state.isLoading}
        onSubmit={this.onSubmit}
        searchUser={this.searchUser}
        email={this.state.email}
        onUnlinkKeys={this.onUnlinkKeys}
      />
    );
  }

  async componentDidMount() {
    const res = await Promise.all([
      BuildingsApi.get(this.state.buildingId),
      BuildingsApi.listCompanies(this.state.buildingId, {
        limit: 100,
        attributes: 'id,name',
        order: 'name desc',
      }),
    ]);

    if (this.state.email) {
      await this.searchUser({ email: this.state.email });
    }

    this.setState({
      building: res[0].data,
      companies: res[1].data,
      isLoading: false,
    });
  }
}

export default injectIntl(AddUser);
