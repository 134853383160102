import * as React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUserData } from '../../actions/users';
import Profile from '../../components/Profile';
import * as UsersApi from '../../api/users';

class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onFileSubmit = this.onFileSubmit.bind(this);
  }

  async onFileSubmit(file) {
    const { data } = await UsersApi.profilePicture(file);

    this.setState({ profile: data });
    this.props.updateUserData(data);

    toast.success('Your profile picture was updated');
  }

  async onSubmit(body) {
    const { data } = await UsersApi.updateProfile(body);

    this.setState({ profile: data });
    this.props.updateUserData(data);

    toast.success('Your profile information was updated');
  }

  render() {
    const { user } = this.props;

    return (
      <Profile
        onFileSubmit={this.onFileSubmit}
        user={user}
        onSubmit={this.onSubmit}
        profile={this.state.profile}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const { data } = await UsersApi.me();

    this.setState({
      profile: data,
      isLoading: false,
    });

    this.props.updateUserData(data);
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => ({
  updateUserData: data => dispatch(updateUserData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
