import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
  Hidden,
  Drawer,
  ListItem,
  List,
  ListItemText,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    textTransform: 'lowercase !important',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    color: '#fff',
  },
  drawerList: {
    '& i': {
      color: '#fff',
    },
  },
  drawerLink: {
    color: '#fff',
    textTransform: 'lowercase',
    textDecoration: 'none',
    display: 'block',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    backgroundColor: '#f5f1ee',
    minHeight: '100vh',

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 32px)',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  bottomBar: {
    top: 'auto',
    bottom: 0,
    borderTop: `1px solid rgba(0, 0, 0, 0.2)`,
    backgroundColor: '#eaeaea',
  },
  tabLink: {
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

export default props => {
  const bigScreen = useMediaQuery(theme => theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const { logout, loggedIn, language, changeLanguage } = props;
  const [opened, setOpened] = React.useState(bigScreen);
  const handleOpened = () => setOpened(!opened);

  const classes = useStyles();
  const [langMenuAnchorEl, setLangAnchorEl] = React.useState(null);
  const langOpen = Boolean(langMenuAnchorEl);

  const onLanguageChange = lng => {
    changeLanguage(lng);
  };

  const handleMenu = setter => event => {
    setter(event.currentTarget);
  };

  const handleClose = setter => () => {
    setter(null);
  };

  const handleDrawerClick = () => {
    handleOpened();
  };

  if (bigScreen && !opened) {
    setOpened(true);
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: opened && bigScreen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleOpened}
            edge="start"
            className={clsx(classes.menuButton, opened && classes.hide)}
          >
            <i className="fa fa-bars"></i>
          </IconButton>
          <div style={{ flexGrow: 1 }}>
            <img
              src="https://podesubir-public.s3-sa-east-1.amazonaws.com/podesubir-logo-green-gray.png"
              alt="logo"
              style={{ maxWidth: '100px' }}
            />
          </div>
          <div style={{ flexGrow: 1, textAlign: 'right' }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu(setLangAnchorEl)}
              color="inherit"
              style={{ fontSize: '1.2rem' }}
            >
              <i className="fa fa-language"></i>&nbsp;{language.toUpperCase()}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={langMenuAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={langOpen}
              onClose={handleClose(setLangAnchorEl)}
            >
              <MenuItem onClick={handleClose(setLangAnchorEl)}>
                <a
                  className={classes.menuLink}
                  onClick={() => onLanguageChange('en')}
                >
                  English
                </a>
              </MenuItem>
              <MenuItem onClick={handleClose(setLangAnchorEl)}>
                <a
                  className={classes.menuLink}
                  onClick={() => onLanguageChange('pt')}
                >
                  Português
                </a>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={opened}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography
            style={{ flexGrow: 1, paddingLeft: '10px', textTransform: 'none' }}
            component="div"
          >
            BACKOFFICE
          </Typography>

          <Hidden lgUp>
            <IconButton onClick={handleOpened} color="inherit">
              <i className="fa fa-chevron-left"></i>
            </IconButton>
          </Hidden>
        </div>
        <Divider />
        <List className={classes.drawerList}>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/buildings" className={classes.drawerLink}>
                  <i className="fa fa-building fa-fw"></i>{' '}
                  <FormattedMessage id="Buildings" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/gateways" className={classes.drawerLink}>
                  <i className="fa fa-building fa-fw"></i> <FormattedMessage id="Gateways" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/users" className={classes.drawerLink}>
                  <i className="fa fa-users fa-fw"></i>{' '}
                  <FormattedMessage id="Users" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/meetings" className={classes.drawerLink}>
                  <i className="fa fa-calendar fa-fw"></i>{' '}
                  <FormattedMessage id="Meetings" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/main-companies" className={classes.drawerLink}>
                  <i className="fa fa-briefcase fa-fw"></i>{' '}
                  <FormattedMessage id="Main companies" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/queue" className={classes.drawerLink}>
                  <i className="fa fa-lock fa-fw"></i>{' '}
                  <FormattedMessage id="Access codes queue" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/tos" className={classes.drawerLink}>
                  <i className="fa fa-list fa-fw"></i>{' '}
                  <FormattedMessage id="ToS & Privacy revisions" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link
                  to="/app/users/registration-stats"
                  className={classes.drawerLink}
                >
                  <i className="fa fa-bar-chart fa-fw"></i>{' '}
                  <FormattedMessage id="Registration stats" />
                </Link>
              }
            />
          </ListItem>
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <Link to="/app/audit" className={classes.drawerLink}>
                  <i className="fa fa-gavel fa-fw"></i>{' '}
                  <FormattedMessage id="Audit" />
                </Link>
              }
            />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleDrawerClick}>
            <ListItemText
              primary={
                <span onClick={() => logout()} className={classes.drawerLink}>
                  <i className="fa fa-sign-out fa-fw"></i> Logout
                </span>
              }
            />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: opened && bigScreen,
        })}
      >
        {props.children}
      </main>
    </div>
  );
};
