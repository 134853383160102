import * as Yup from 'yup';
import * as ValidateRgCpf from 'cpf-rg-validator';
import { yupToFormErrors } from 'formik';

const strRequired = Yup.string().required('Required');
const email = Yup.string().email('Invalid email').required('Required');

export const LoginSchema = Yup.object().shape({
  username: email,
  password: strRequired,
});

export const ChangePwdSchema = Yup.object().shape({
  password: strRequired,
  password_confirm: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password')], 'Password confirm does not match'),
});

export const ProfileSchema = Yup.object().shape({
  email,
  name: strRequired,
  surname: strRequired,
});

const TotpSchema = Yup.object().when('code_type', {
  is: 'DYNAMIC_TOTP_ENCRYPTED',
  then: Yup.object().shape({
    encryption_key: Yup.string().min(32).max(32).required('Required'),
    encryption_iv: Yup.string().min(16).max(16).required('Required'),
    step: Yup.number().required('Required'),
    secret: Yup.string().min(16).max(16).required('Required'),
    timezone: Yup.string().required('Required'),
    digits: Yup.number().required('Required'),
  }),
  otherwhise: Yup.object().shape({
    encryption_key: Yup.string().nullable(),
    encryption_iv: Yup.string().nullable(),
    step: Yup.number().nullable(),
    secret: Yup.string().nullable(),
    timezone: Yup.string().nullable(),
    digits: Yup.number().nullable(),
  }),
});

export const AddBuildingSchema = Yup.object().shape({
  name: strRequired,
  address: strRequired,
  access_types: Yup.array().of(Yup.string()).min(1, 'Select at least one').required('Required'),
  access_source: Yup.string(),
  totp: TotpSchema,
  code_spec: Yup.number('Numeric value').nullable(),
  facility_codes: Yup.array().of(Yup.string()),
  code_algorithm: strRequired,
  code_type: Yup.string().oneOf(['STATIC', 'DYNAMIC_TOTP_ENCRYPTED']).required('Required'),
  access_gateway_id: Yup.mixed().when('access_source', {
    is: 'manual',
    then: Yup.array().of(Yup.number()).nullable(),
    otherwise: Yup.array().of(Yup.number()).required('Required'),
  }),
});

export const EditBuildingSchema = Yup.object().shape({
  name: strRequired,
  address: strRequired,
  access_types: Yup.array().of(Yup.string()).min(1, 'Select at least one').required('Required'),
  access_source: Yup.string(),
  totp: TotpSchema,
  code_spec: Yup.number('Numeric value').nullable(),
  facility_codes: Yup.array().of(Yup.string()),
  code_algorithm: strRequired,
  code_type: Yup.string().oneOf(['STATIC', 'DYNAMIC_TOTP_ENCRYPTED']).required('Required'),
  /*access_gateways: Yup.mixed().when('access_source', {
    is: 'manual',
    then: Yup.array().of(Yup.number()).nullable(),
    otherwise: Yup.array().of(Yup.number()).required('Required'),
  }),*/
});

export const AddGatewaySchema = Yup.object().shape({
  name: strRequired,
  config: Yup.object().shape({
    create_access_code_url: Yup.string().nullable(),
    update_access_code_url: Yup.string().nullable(),
    cancel_access_code_url: Yup.string().nullable(),
    get_access_code_url: Yup.string().nullable(),
    schedule_before_date: Yup.number(),
    create_users: Yup.boolean(),
    create_users_url: Yup.string().nullable(),
    update_users: Yup.boolean(),
    update_users_url: Yup.string().nullable(),
    delete_users: Yup.boolean(),
    delete_users_url: Yup.string().nullable(),
    change_users_code: Yup.boolean(),
    change_users_code_url: Yup.string().nullable(),
    merge_schedule_invites: Yup.boolean(),
  }).required(),
  generate_card_number: Yup.boolean(),
  disabled: Yup.boolean(),
});

export const EditGatewaySchema = Yup.object().shape({
  name: strRequired,
  config: Yup.object().shape({
    create_access_code_url: Yup.string().nullable(),
    update_access_code_url: Yup.string().nullable(),
    cancel_access_code_url: Yup.string().nullable(),
    get_access_code_url: Yup.string().nullable(),
    schedule_before_date: Yup.number(),
    create_users: Yup.boolean(),
    create_users_url: Yup.string().nullable(),
    update_users: Yup.boolean(),
    update_users_url: Yup.string().nullable(),
    delete_users: Yup.boolean(),
    delete_users_url: Yup.string().nullable(),
    change_users_code: Yup.boolean(),
    change_users_code_url: Yup.string().nullable(),
    merge_schedule_invites: Yup.boolean(),
  }).required(),
  generate_card_number: Yup.boolean(),
  disabled: Yup.boolean(),
});

export const AddCompanySchema = Yup.object().shape({
  name: strRequired,
  floor: strRequired,
  room: Yup.string().nullable(),
  access_before_minutes: Yup.number().required(),
});

export const MainCompanySchema = Yup.object().shape({
  name: strRequired,
  layout: strRequired,
});

export const EditCompanySchema = Yup.object().shape({
  name: strRequired,
  floor: strRequired,
  room: Yup.string().nullable(),
  access_before_minutes: Yup.number().required(),
});

export const CreateInvitationSchema = Yup.object().shape({
  guest_email: email,
  subject: strRequired,
  building_id: Yup.number().required('Select a building'),
});

export const AddKeySchema = Yup.object().shape({
  keys: Yup.array()
    .of(Yup.string().required('Required'))
    .required('Required')
    .min(1),
  valid_until: Yup.string().required('Required'),
  single_use: Yup.boolean(),
  access_types: Yup.array()
    .of(Yup.string())
    .min(1, 'Select at least one')
    .required('Required'),
  type: Yup.string().required('Required'),
});

export const AddAssignedKeySchema = Yup.object().shape({
  assigned_to_user: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
        user_id: Yup.number().required('Required'),
      })
    )
    .required('Required')
    .min(1),
  valid_until: Yup.string().required('Required'),
  single_use: Yup.boolean(),
  access_types: Yup.array()
    .of(Yup.string())
    .min(1, 'Select at least one')
    .required('Required'),
  type: Yup.string().required('Required'),
});

export const EditUserSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  surname: Yup.string().nullable(),
  email: Yup.string().email(),
  nationality: Yup.string().nullable(),
  cellphone: Yup.string().nullable(),
  birthdate: Yup.string().nullable(),
  id_number: Yup.string().nullable(),
  cpf: Yup.string()
    .test(
      'cpf',
      'Invalid CPF number',
      value => !value || ValidateRgCpf.cpf(value)
    )
    .nullable(),
  company: Yup.string().nullable(),
  company_role: Yup.string().nullable(),
  active: Yup.boolean(),
  admin: Yup.boolean(),
  should_change_password: Yup.boolean(),
});

export const AddRevisionSchema = Yup.object().shape({
  type: strRequired,
  description: strRequired,
});

export const CreateReview = Yup.object().shape({
  fields: Yup.array()
    .of(
      Yup.object().shape({
        field: Yup.string().required('Required'),
        reason: Yup.string().required('Required'),
      })
    )
    .required('Required')
    .min(1),
});

export const RequestPwdRecoverySchema = Yup.object().shape({
  email,
});

export const AddUserSchema = Yup.object().shape({
  email,
  name: Yup.string(),
  surname: Yup.string(),
  company: Yup.string(),
  company_role: Yup.string(),
  AccessCode: Yup.object()
    .shape({
      key: Yup.string().required('Required'),
    })
    .required(),
  role: strRequired,
});
