import * as React from 'react';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Paper, LinearProgress, Box } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { FormattedMessage } from 'react-intl';
import { Input, Select } from '../Shared/BsInput';
import Modal from 'react-bootstrap4-modal';
import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
  COMPANY_MANAGER,
  COMPANY_SECRETARY,
} from '../../constants';
import {
  RequestPwdRecoverySchema,
  AddUserSchema,
} from '../../validation-schemas';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
  company,
  disableInputs,
  facilityCodes,
  companies,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container justify="center">
        <Grid item xs={12} md={9}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={<FormattedMessage id="User email" />}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={<FormattedMessage id="Surname" />}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Input
            type="text"
            value={values.company}
            onChange={handleChange}
            name="company"
            label={<FormattedMessage id="Company" />}
            errors={errors}
            disabled={!!disableInputs}
          />
          <Select
            value={values.role}
            onChange={handleChange}
            name="role"
            label={<FormattedMessage id="Role" />}
            errors={errors}
            options={[
              ...(!values.company_id
                ? [{ value: BUILDING_MANAGER, label: BUILDING_MANAGER }]
                : []),
              ...(values.company_id
                ? [{ value: COMPANY_MANAGER, label: COMPANY_MANAGER }]
                : []),
              ...(values.company_id
                ? [{ value: COMPANY_SECRETARY, label: COMPANY_SECRETARY }]
                : []),
            ].concat([
              { value: HOST_EMPLOYEE, label: HOST_EMPLOYEE },
              { value: EMPLOYEE, label: EMPLOYEE },
              ...(!values.company_id
                ? [{ value: SECURITY, label: SECURITY }]
                : []),
            ])}
          />
          {!!companies.length && (
            <Select
              value={values.company_id}
              onChange={handleChange}
              name="company_id"
              label={<FormattedMessage id="Company" />}
              errors={errors}
              options={[{ value: '', label: '-' }].concat(
                companies.map(c => ({ value: c.id, label: c.name }))
              )}
            />
          )}
          <Grid spacing={2} item container justify="center">
            {!!facilityCodes && !!facilityCodes.length && (
              <Grid item xs={3}>
                <Select
                  value={values.AccessCode.facility_code}
                  onChange={handleChange}
                  name="AccessCode.facility_code"
                  label={<FormattedMessage id="Facility code" />}
                  errors={errors}
                  options={facilityCodes.map(fc => ({ label: fc, value: fc }))}
                  disabled={facilityCodes.length === 1}
                />
              </Grid>
            )}
            <Grid item xs={facilityCodes && facilityCodes.length ? 9 : 12}>
              <Input
                type="text"
                value={values.AccessCode.key}
                onChange={handleChange}
                name="AccessCode.key"
                label={<FormattedMessage id="Access code" />}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting || !isValid}
            fullWidth
          >
            <FormattedMessage id="Add user" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderSearchForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container justify="center">
        <Grid item xs={9}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={<FormattedMessage id="User email" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting || !isValid}
            fullWidth
          >
            {'next'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderModalUnlink = ({
  unlinkKeyModal,
  setUnlinkKeyModal,
  setLoading,
  loading,
  setAnchorEl,
  anchorEl,
  onUnlinkKeys,
  onSubmit,
}) => {
  return (
    <Modal visible={unlinkKeyModal.show} onClose={() => setUnlinkKeyModal({ ...unlinkKeyModal, show: false })}>
      <div className="modal-header">
        <h5 className="modal-title"><FormattedMessage id="Access code linked to another user" /></h5>
      </div>
      <div className="modal-body">
        <FormattedMessage id="This access code is associated with:" />
        <div style={{marginTop: 20}}><b><FormattedMessage id="Name" /></b> {unlinkKeyModal?.info?.name}</div>
        <div><b><FormattedMessage id="Surname" /></b> {unlinkKeyModal?.info?.surname}</div>
        <div><b><FormattedMessage id="Companies" /></b> {unlinkKeyModal?.info?.companies?.join(', ')}</div>
        <div><b><FormattedMessage id="Email" /></b> {unlinkKeyModal?.info?.email}</div>
        {loading && (<LinearProgress style={{ marginTop: 15 }} />)} 
      </div>
      <div className="modal-footer">
        <Button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          variant="text"
          color="secondary"
          disabled={loading}
        >
          <FormattedMessage id="Associate with new user" />
        </Button>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            <b><FormattedMessage id="Are you sure about that?" /></b>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10}}>
              <Button
                style={{ height: 25 }}
                onClick={(e) => {
                  setAnchorEl(null);
                  setUnlinkKeyModal({ ...unlinkKeyModal, show: false })
                }}
                variant="text"
                color="primary"
                disabled={loading}
              >
                <FormattedMessage id="No" />
              </Button>
              <Button
                style={{ height: 25 }}
                onClick={async () => {
                  setAnchorEl(null);
                  setLoading(true);
                  await onUnlinkKeys(unlinkKeyModal.info.id);
                  await onSubmit(unlinkKeyModal.values);
                  setLoading(false);
                }}
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                <FormattedMessage id="Yes" />
              </Button>
            </div>
          </Box>
        </Popover>
        <Button
          onClick={() => setUnlinkKeyModal({ ...unlinkKeyModal, show: false })}
          variant="text"
          color="primary"
          disabled={loading}
        >
          <FormattedMessage id="Return" />
        </Button>
      </div>
    </Modal>
  );
};

export default ({
  building,
  user,
  email,
  searched,
  searchUser,
  onSubmit,
  isLoading,
  companies,
  onUnlinkKeys,
}) => {
  const classes = useStyles();
  const [unlinkKeyModal, setUnlinkKeyModal] = React.useState({show: false, info: {}, values: {}});
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (isLoading) {
    return null;
  }

  const defaultFacilityCode =
    building.facility_codes && building.facility_codes.length
      ? building.facility_codes[0]
      : '';

  return (
    <>
    <Paper className={classes.paper}>
      <Typography variant="h6" align="center">
        {`${'Add user to building'}: "${building.name}"`}
      </Typography>
      {!!user && !!user.Roles.length && (
        <Grid spacing={2} container justify="center">
          <Grid item xs={12} md={9}>
            <div className="alert alert-info text-center mt-3">
              <Typography>
                <FormattedMessage id="Roles in the building" />: &nbsp;
                {user.Roles.map(r => r.role).join(', ')}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
      {!user && !searched && (
        <Formik
          initialValues={{
            email: '',
            role: EMPLOYEE,
          }}
          validationSchema={RequestPwdRecoverySchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
              await searchUser(values);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderSearchForm {...p} {...{ companies }} />}
        />
      )}

      {!user && searched && (
        <>
          <Formik
            initialValues={{
              id: 0,
              email: email || '',
              name: '',
              surname: '',
              company: '',
              company_role: '',
              role: EMPLOYEE,
              AccessCode: {
                key: '',
                facility_code: defaultFacilityCode,
              },
            }}
            validationSchema={AddUserSchema}
            enableReinitialize={true}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, resetForm }
            ) => {
              try {
                const {error, info} = await onSubmit(values);

                if(error){
                  setUnlinkKeyModal({show: true, info, values});
                  setSubmitting(false);
                }
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={p => (
              <RenderForm
                {...p}
                {...{ companies, facilityCodes: building.facility_codes }}
              />
            )}
          />
        </>
      )}
      {!!user && (
        <Formik
          initialValues={{
            id: user.id || 0,
            email: user.email || '',
            name: user.name || '',
            surname: user.surname || '',
            company: user.company || '',
            company_role: user.company_role || '',
            role: HOST_EMPLOYEE,
            // bien merecida
            AccessCode: {
              key: user.AccessCode ? user.AccessCode.key : '',
              facility_code: user.AccessCode
                ? user.AccessCode.facility_code || defaultFacilityCode
                : defaultFacilityCode,
            },
          }}
          validationSchema={AddUserSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
              const {error, info} = await onSubmit(values);

              if(error){
                setUnlinkKeyModal({show: true, info, values});
                setSubmitting(false);
              }
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => (
            <RenderForm
              {...p}
              {...{
                companies,
                disableInputs: true,
                facilityCodes: building.facility_codes,
              }}
            />
          )}
        />
      )}
    </Paper>
    <RenderModalUnlink 
      loading={loading}
      setLoading={setLoading}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      onSubmit={onSubmit}
      onUnlinkKeys={onUnlinkKeys}
      setUnlinkKeyModal={setUnlinkKeyModal}
      unlinkKeyModal={unlinkKeyModal}
    />
    </>
  );
};
