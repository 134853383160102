import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import GatewayForm from '../../components/Gateways/form';
import * as GatewaysApi from '../../api/gateways';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';

const nullify = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (_.isString(obj[key]) && obj[key].trim().length === 0) {
      acc[key] = null;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

class GatewayEditContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gatewayId: this.props.match.params.gatewayId,
      gateway: {},
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.enableDisable = this.enableDisable.bind(this);
  }

  async onSubmit(body) {
    body.config = nullify(body.config);

    await GatewaysApi.update(this.state.gatewayId, _.pick(body, [
      'name', 'config', 'disabled', 'generate_card_number'
    ]));

    toast.success(this.props.intl.formatMessage({ id: 'The gateway information has been updated' }));
    this.props.history.push('/app/gateways');
  }

  async enableDisable(disable){
    const changeText = disable ? 'enable' : 'disable';
    const complementaryText = disable ? '' : 'Buildings will be unliked';
    const onConfirmClick = async () => {
      if (disable){
        await GatewaysApi.enable(this.state.gatewayId);
      }
      else{
        await GatewaysApi.disable(this.state.gatewayId);
      }
      toast.success(this.props.intl.formatMessage({ id: 'The gateway has been updated' }));
      this.props.history.push('/app/gateways')
    }

    confirmAlert({
      message: this.props.intl.formatMessage({ 
        id: `Are you sure you want to ${changeText} the gateway? ${complementaryText}` 
      }),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  render() {
    return (
      <GatewayForm
        onSubmit={this.onSubmit}
        gateway={this.state.gateway}
        isLoading={this.state.isLoading}
        enableDisable={this.enableDisable}
      />
    );
  }

  async componentDidMount() {
    const { data } = await GatewaysApi.get(this.state.gatewayId);

    this.setState({
      gateway: {
        generate_card_number: false,
        ...data,
        config: {
          ...{
            create_access_code_url: null,
            update_access_code_url: null,
            cancel_access_code_url: null,
            get_access_code_url: null,
            schedule_before_date: '5',
            create_users: false,
            create_users_url: null,
            update_users: false,
            update_users_url: null,
            delete_users: false,
            delete_users_url: null,
            change_users_code: false,
            change_users_code_url: null,
            stats_url: null,
            merge_schedule_invites: false,
            anonymize: false,
          },
          ...data.config,
        }
      },
      isLoading: false,
    });
  }
}

export default injectIntl(GatewayEditContainer);
