import * as React from 'react';
import {
  Grid,
  Typography,
  Button,
  TableCell,
  TableRow,
  LinearProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import NoRecords from '../Shared/NoRecords';
import { Input, Select } from '../Shared/BsInput';
import { RenderModalEditRole, RenderModalAddRole } from './modal-roles';

import {
  BUILDING_MANAGER,
  EMPLOYEE,
  HOST_EMPLOYEE,
  SECURITY,
  COMPANY_MANAGER,
  COMPANY_SECRETARY
} from '../../constants';
import { toast } from 'react-toastify';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid spacing={2} container>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.email}
            onChange={handleChange}
            name="email"
            label={<FormattedMessage id="Email" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Input
            type="text"
            value={values.surname}
            onChange={handleChange}
            name="surname"
            label={<FormattedMessage id="Surname" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            style={{ paddingTop: '8px' }}
            value={values.role}
            onChange={handleChange}
            name="role"
            label={<FormattedMessage id="Role" />}
            errors={errors}
            options={[
              { value: '', label: '-' },
              { value: BUILDING_MANAGER, label: BUILDING_MANAGER },
              { value: COMPANY_MANAGER, label: COMPANY_MANAGER },
              { value: COMPANY_SECRETARY, label: COMPANY_SECRETARY },
              { value: HOST_EMPLOYEE, label: HOST_EMPLOYEE },
              { value: EMPLOYEE, label: EMPLOYEE },
              { value: SECURITY, label: SECURITY },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            className="mt-3"
            variant="contained"
            color="default"
            type="submit"
            disabled={isSubmitting}
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderTable = ({ onPageChange, pages, page, users, removeUser, addRole, editRole, companies }) => {
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState({ isEditModalOpen: false, isAddModalOpen: false, userbuilding: {} });

  const onEdit = React.useCallback((userBuildingId, newRole, company) => editRole(userBuildingId, newRole, company), []);

  const onAdd = React.useCallback((userId, role, company) => addRole(userId, role, company), []);

  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Email" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Role" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Company" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Access code" />
          </TableCell>
          <TableCell>
            {/* <FormattedMessage id="Edit role" /> */}
          </TableCell>
        </TableRow>
      }
      body={
        <>
          {users.map((userbuilding, i) => {
            return (
              <TableRow key={i}>
                <TableCell>
                  {userbuilding.User.name} {userbuilding.User.surname}
                </TableCell>
                <TableCell>{userbuilding.User.email}</TableCell>
                <TableCell>{userbuilding.role}</TableCell>
                <TableCell>
                  {userbuilding.Company ? userbuilding.Company.name : '-'}
                </TableCell>
                <TableCell>
                  {userbuilding.User.AccessCode.length
                    ? userbuilding.User.AccessCode[0].key
                    : '-'}
                </TableCell>
                <TableCell >
                  <div style={{display: 'flex', gap: 8}}>
                    <Button
                      type="button"
                      variant="outlined"
                      size="medium"
                      style={{
                        color: 'green',
                        borderColor: 'green',
                        width: 40,
                        minWidth: 40
                      }}
                      onClick={() => setData({ isAddModalOpen: true, userbuilding })}
                      title={formatMessage({ id: 'Add role' })}
                    >
                      <i className='fa fa-plus'/>
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      style={{ 
                        color: 'darkblue',
                        borderColor: 'darkblue',
                        width: 40,
                        minWidth: 40
                      }}
                      color="primary"
                      size="medium"
                      onClick={() => setData({ isEditModalOpen: true, userbuilding })}
                      title={formatMessage({ id: 'Edit role' })}
                    >
                      <i className='fa fa-pencil'/>
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      size="medium"
                      style={{ width: 40, minWidth: 40 }}
                      onClick={() => removeUser(userbuilding.id)}
                      title={formatMessage({ id: 'Remove role' })}
                    >
                      <i className='fa fa-trash'/>
                    </Button>
                  </div>
                </TableCell>                
              </TableRow>
            );
          })}
          <RenderModalEditRole data={data} setData={setData} onEdit={onEdit} companies={companies} />
          <RenderModalAddRole data={data} setData={setData} onAdd={onAdd} companies={companies} />
        </>
      }
    />
  );
};

export default ({
  onPageChange,
  pages,
  page,
  users,
  building,
  removeUser,
  addRole,
  editRole,
  companies,
}) => {
  return (
    <>
      <ContainerCard
        xs={12}
        md={12}
        lg={12}
        header={
          <Typography variant="h6" align="center">
            "{building.name}" <FormattedMessage id="users" />
          </Typography>
        }
        content={
          <>
            <Formik
              initialValues={{
                email: '',
                name: '',
                surname: '',
                role: '',
              }}
              enableReinitialize={true}
              onSubmit={async (
                values,
                { setSubmitting, setErrors, resetForm }
              ) => {
                try {
                  await onPageChange({ page: 1, ...values });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => <RenderForm {...p} />}
            />
            <hr />
            <div style={{ textAlign: 'right' }}>
              <Button
                component={Link}
                variant="contained"
                size="small"
                color="primary"
                to={`/app/buildings/${building.id}/users/add`}
              >
                <FormattedMessage id="Add user" />
              </Button>
            </div>
            {!users.length && <NoRecords />}
            {!!users.length && (
              <RenderTable
                {...{ onPageChange, pages, page, users, removeUser, addRole, editRole, companies }}
              />
            )}
          </>
        }
      />
    </>
  );
};
