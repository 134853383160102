import * as React from 'react';
import CompaniesIndex from '../../components/MainCompanies/index';
import * as CompaniesApi from '../../api/main-companies';

export default class CompaniesIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      pages: 0,
      page: 1,
      isLoading: true,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page }) {
    const { data, headers } = await CompaniesApi.list({
      limit: 10,
      offset: (page - 1) * 10,
      order: 'id DESC',
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      companies: data,
      pages,
      page,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <CompaniesIndex
        companies={this.state.companies}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}
