import * as React from 'react';
import { Formik } from 'formik';
import { Typography, Button } from '@material-ui/core';
import { Input } from '../Shared/BsInput';
import { LoginSchema } from '../../validation-schemas';
import ContainerCard from '../Shared/container-card';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.username}
        onChange={handleChange}
        name="username"
        label={'Email'}
        errors={errors}
      />
      <Input
        type="password"
        value={values.password}
        onChange={handleChange}
        name="password"
        label={'Password'}
        errors={errors}
      />
      <hr />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={isSubmitting}
        fullWidth
      >
        Login
      </Button>
    </form>
  );
};

export default ({ onSubmit }) => {
  return (
    <ContainerCard
      xs={12}
      md={4}
      header={
        <Typography variant="h6" align="center">
          Login
        </Typography>
      }
      content={
        <React.Fragment>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await onSubmit(values);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={RenderForm}
          />
        </React.Fragment>
      }
    />
  );
};
