import * as React from 'react';
import EmailsIndex from '../../components/SchedulerEmails/index';
import * as EmailsApi from '../../api/scheduler-emails';

export default class SchedulerEmailsIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      pages: 0,
      page: 1,
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page }) {
    const { data, headers } = await EmailsApi.list({
      limit: 20,
      offset: (page - 1) * 20,
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      emails: data,
      pages,
      page,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <EmailsIndex
        emails={this.state.emails}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}
