import * as React from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Button, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, tos, destroy }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Type" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Description" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Date" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {tos.map((b, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{b.id}</TableCell>
                <TableCell>{b.type}</TableCell>
                <TableCell>{b.description}</TableCell>
                <TableCell>
                  {moment(b.createdAt).format('DD-MM-YYYY')}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    onClick={() => destroy(b.id)}
                    fullWidth
                  >
                    <FormattedMessage id="Remove revision" />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, tos, destroy }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-book"></i>{' '}
          <FormattedMessage id="TOS and Privacy Policies revisions" />
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/tos/create`}
            >
              <FormattedMessage id="Create new revision" />
            </Button>
            <hr />
          </div>
          {!tos.length && <NoRecords />}
          {!!tos.length && (
            <RenderTable {...{ onPageChange, pages, page, tos, destroy }} />
          )}
        </React.Fragment>
      }
    />
  );
};
