import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import CompaniesForm from '../../components/Companies/form';
import * as BuildingsApi from '../../api/buildings';
import * as GatewaysApi from '../../api/gateways';
import * as MainCompaniesApi from '../../api/main-companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      companyId: this.props.match.params.companyId,
      building: null,
      company: null,
      isLoading: true,
      mainCompanies: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const clean = _.pick(body, [
      'name',
      'floor',
      'room',
      'access_before_minutes',
      'parent_id',
      'logo',
      'background',
      'layout',
      'allow_arrival_announcement',
      'phone',
      'show_code_before_minutes',
      'access_gateways',
    ]);

    await BuildingsApi.updateCompany(
      this.state.buildingId,
      this.state.companyId,
      clean
    );

    toast.success(
      this.props.intl.formatMessage({ id: 'The company has been updated' })
    );
    this.props.history.push(
      `/app/buildings/${this.state.buildingId}/companies`
    );
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    
    return (
      <CompaniesForm
        company={this.state.company}
        onSubmit={this.onSubmit}
        isLoading={this.state.isLoading}
        mainCompanies={this.state.mainCompanies}
        building={this.state.building}
      />
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.getCompany(
      this.state.buildingId,
      this.state.companyId
    );

    const { data: building } = await BuildingsApi.get(this.state.buildingId);
    const { data: gateways } = await GatewaysApi.list({ attributes: 'id,name', where: { disabled: false } });

    const res = await MainCompaniesApi.listPublic();

    this.setState({
      mainCompanies: res.data,
      company: {
        ...data,
      },
      building: {
        ...building,
        gateways
      },
      isLoading: false,
    });
  }
}

export default injectIntl(CompaniesCreateContainer);
