import * as React from 'react';
import isBoolean from 'lodash/isBoolean';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const Tr = ({ label, value, changed }) => {
  return (
    <TableRow>
      <TableCell>
        <b>{label}</b>
      </TableCell>
      <TableCell align="center">
        {isBoolean(value) ? (value ? 'Yes' : 'No') : value}
      </TableCell>
      <TableCell align="right">
        {changed && (
          <>
            <FormattedMessage id="Field already changed since review" />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ({ user }) => {
  if (!user.Review) {
    return (
      <Paper style={{ padding: '10px' }}>
        <div className="alert alert-info text-center">
          <FormattedMessage id="The user has no admin profile reviews" />
        </div>
      </Paper>
    );
  }

  return (
    <Paper style={{ padding: '10px' }}>
      <Table size="small">
        <TableBody>
          <Tr label="Fields that should be reviewed" value={null} />
          {user.Review.fields.map((field, i) => {
            return (
              <Tr
                key={i}
                label={<FormattedMessage id={field.field} />}
                value={field.reason}
                changed={field.changed}
              />
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};
