import request from './index';

export function rotateDocument(id, direction) {
  return request({
    method: 'PUT',
    path: `/api/admin/documents/${id}/rotate?direction=${direction}`,
  });
}

export function flopDocument(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/documents/${id}/flop`,
  });
}
