import axios from 'axios';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function me() {
  return request({
    method: 'GET',
    path: '/api/users',
  });
}

export function updateProfile(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users',
  });
}

export function changePassword(body) {
  return request({
    body,
    method: 'PUT',
    path: '/api/users/change-password',
  });
}

export function rekognition(id) {
  return request({
    method: 'GET',
    path: `/api/admin/users/${id}/rekognition`,
  });
}

export function approve(id) {
  return request({
    method: 'POST',
    path: `/api/admin/users/${id}/approve`,
  });
}

export function reject(id, body) {
  return request({
    body,
    method: 'POST',
    path: `/api/admin/users/${id}/reject`,
  });
}

export function profilePicture(file) {
  const fd = new FormData();
  fd.append('profile_picture', file);

  store.dispatch(req());

  return axios
    .put(`${BASEURL}/api/users/profile-picture`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/users',
  });
}

export function autocomplete(params) {
  return request(
    {
      params,
      method: 'GET',
      path: '/api/admin/users/autocomplete',
    },
    true
  );
}

export function update(id, body) {
  return request({
    body,
    method: 'PUT',
    path: `/api/admin/users/${id}`,
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/admin/users/${id}`,
  });
}

export function buildings(id) {
  return request({
    method: 'GET',
    path: `/api/admin/users/${id}/buildings`,
  });
}

export function removeBuilding(id, buildingId) {
  return request({
    method: 'DELETE',
    path: `/api/admin/users/${id}/buildings/${buildingId}`,
  });
}

export function forceLogout(id) {
  return request({
    method: 'POST',
    path: `/api/admin/users/${id}/revoke-jwt`,
  });
}

export function deleteAccount(id) {
  return request({
    method: 'POST',
    path: `/api/admin/users/delete-account/${id}`,
  });
}

export function registrationStats(body) {
  return request({
    body,
    method: 'POST',
    path: `/api/admin/users/registration-stats`,
  });
}
