import axios from 'axios';
import request from './index';
import { toast } from 'react-toastify';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

const catchError = error => {
  const { data } = error.response;

  toast.error(data.message || data.error);

  store.dispatch(res());

  throw error;
};

export function me() {
  return request({
    method: 'GET',
    path: '/api/users',
  });
}

export function login(data) {
  store.dispatch(req());

  return axios({
    data,
    method: 'POST',
    url: `${BASEURL}/api/auth/login`,
  })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}
