import * as React from 'react';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
  Tooltip
} from '@material-ui/core';
import moment from 'moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';
import { Input, Select } from '../Shared/BsInput';
import { useHistory } from 'react-router-dom';
import ErrorsModal from '../../containers/Queues/errors';

const RenderTable = ({
  onPageChange,
  pages,
  page,
  queues,
  forceRun,
  destroy,
  setInfoOpenModal,
}) => {
  const history = useHistory();

  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Invitation ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Date" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Pending" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Attempts" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {queues.map((queue, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{queue.id}</TableCell>
                <TableCell>{queue.invitation_id}</TableCell>
                <TableCell>
                  {moment(queue.date).format('DD-MM-YYYY HH:mm')}
                </TableCell>
                <TableCell>{queue.pending ? 'Yes' : 'No'}</TableCell>
                <TableCell>{queue.attempts}</TableCell>
                <TableCell>
                  {queue.pending && (
                    <>
                      <div style={{ display: "flex", columnGap:"10px" }}>
                        <Tooltip title="Go to invite">
                          <i className="fa fa-eye" style={{ color:"blue", cursor:"pointer" }} onClick={() => history.push(`/app/meetings/${queue.Invitation.meeting_id}`)}></i>
                        </Tooltip>

                        <Tooltip title="Force run">
                          <i className="fa fa-rotate-right" style={{ color:"green", cursor:"pointer" }} onClick={() => forceRun(queue.id)}></i>
                        </Tooltip>

                        <Tooltip title="View errors log">
                          <i className="fa fa-file-text-o" style={{ color:"orange", cursor:"pointer" }} onClick={() => setInfoOpenModal(queue.id, queue.invitation_id)}></i>
                        </Tooltip>

                        <Tooltip title="Cancel">
                          <i className="fa fa-trash" style={{ color:"red", cursor:"pointer" }} onClick={() => destroy(queue.id)}></i>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.invitation_id}
            onChange={handleChange}
            name="invitation_id"
            label={<FormattedMessage id="Invitation ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.pending}
            onChange={handleChange}
            name="pending"
            label={<FormattedMessage id="Pending" />}
            errors={errors}
            options={[
              { label: 'All', value: '__none__' },
              { label: 'Pending', value: '1' },
              { label: 'Not pending', value: '0' },
            ]}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, pages, page, queues, forceRun, destroy }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [infosModal, setInfosModal] = React.useState({
    queue_id: null,
    invitation_id: null,
  });

  const setInfoOpenModal = (queue_id, invitation_id) => {
    setInfosModal({ queue_id, invitation_id })
    setOpenModal(true);
  }

  return (
    <React.Fragment>
      <ContainerCard
        xs={12}
        md={12}
        header={
          <Typography variant="h6">
            <i className="fa fa-list"></i>{' '}
            <FormattedMessage id="Access codes queue" />
          </Typography>
        }
        content={
          <React.Fragment>
            <Formik
              initialValues={{
                id: '',
                invitation_id: '',
                pending: '1',
                order: 'date DESC',
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await onPageChange({ page: 1, ...values });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={RenderForm}
            />
            <hr />
            {!queues.length && <NoRecords />}
            {!!queues.length && (
              <RenderTable
                {...{ onPageChange, pages, page, queues, forceRun, destroy, setInfoOpenModal }}
              />
            )}
            {openModal && 
              <ErrorsModal
                setOpenModal={setOpenModal}
                infos={infosModal}
              />
            }
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};
