import * as React from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';

export default props => {
  const { xs, md, header, content } = props;

  return (
    <Grid container direction="row" justify="center" spacing={3}>
      <Grid item xs={xs || 12} md={md || 12}>
        <Card raised={true}>
          <div className="md-card-header">{header}</div>
          <CardContent>{content}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
