import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import ChangePwdForm from '../../components/Profile/change-password';
import * as UsersApi from '../../api/users';

class ChangePwdContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await UsersApi.changePassword(body);

    toast.success(
      this.props.intl.formatMessage({ id: 'Your password has been changed' })
    );
    this.props.history.push('/app/profile');
  }

  render() {
    return <ChangePwdForm onSubmit={this.onSubmit} />;
  }
}

export default injectIntl(ChangePwdContainer);
