import * as React from 'react';
import * as _ from 'lodash';
import MeetingsIndex from '../../components/Meetings/index';
import * as MeetingsApi from '../../api/meetings';
import * as BuildingsApi from '../../api/buildings';

export default class MeetingsIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetings: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        id: '',
        building_id: '',
        host_user_id: '',
        guest_user_id: '',
        invitation_id: '',
        date_from: null,
        date_to: null,
        createdAt: null,
        order: 'id desc',
      },
      buildings: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...where }) {
    const { data, headers } = await MeetingsApi.list({
      limit: 10,
      offset: (page - 1) * 10,
      ...(_.isEmpty(where) ? this.state.where : where),
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      meetings: data,
      pages,
      page,
      where: _.isEmpty(where) ? this.state.where : where,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });

    const { data } = await BuildingsApi.list({
      limit: 10000,
      attributes: 'id,name',
      order: 'name ASC',
    });

    this.setState({ buildings: data });
  }

  render() {
    return (
      <MeetingsIndex
        meetings={this.state.meetings}
        buildings={this.state.buildings}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}
