import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ContainerCard from '../Shared/container-card';

export default ({ meeting, isLoading, destroy }) => {
  if (isLoading) {
    return <div></div>;
  }

  const fields = ['id', 'uid', 'subject', 'notes', 'status'];
  const dates = ['date_from', 'date_to', 'cancelled_at', 'createdAt'];

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-envelope-open"></i>{' '}
          <FormattedMessage id="Meeting detail" />
        </Typography>
      }
      content={
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {fields.map((field, i) => {
              return (
                <div key={i}>
                  <small className="font-weight-bold">
                    <FormattedMessage
                      id={field.toUpperCase().replace(/_/g, ' ')}
                    />
                  </small>
                  <div className="text-uppercase">{meeting[field] || '-'}</div>
                </div>
              );
            })}
            {dates.map((field, i) => {
              return (
                <div key={i}>
                  <small className="font-weight-bold">
                    <FormattedMessage
                      id={field.toUpperCase().replace(/_/g, ' ')}
                    />
                  </small>
                  <div className="text-uppercase">
                    {meeting[field]
                      ? moment(meeting[field]).format('DD-MM-YYYY HH:mm')
                      : '-'}
                  </div>
                </div>
              );
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Typography color="secondary">
                <FormattedMessage id="BUILDING" />
              </Typography>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="ID" />
                </small>
                <div className="text-uppercase">
                  <div>{meeting.Building.id}</div>
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="NAME" />
                </small>
                <div className="text-uppercase">
                  <div>{meeting.Building.name}</div>
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="ADDRESS" />
                </small>
                <div className="text-uppercase">
                  <div>{meeting.Building.address}</div>
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="ACCESS TYPES" />
                </small>
                <div className="text-uppercase">
                  <div>{meeting.Building.access_types}</div>
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="ACCESS SOURCE" />
                </small>
                <div className="text-uppercase">
                  <div>{meeting.Building.access_source}</div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <Typography color="secondary">
                <FormattedMessage id="HOST" />
              </Typography>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="ID" />
                </small>
                <div className="text-uppercase">
                  <Link
                    to={`/app/users/${meeting.Host.id}/edit`}
                    target="_blank"
                  >
                    {meeting.Host.id}
                  </Link>
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="NAME" />
                </small>
                <div className="text-uppercase">
                  {meeting.Host.name} {meeting.Host.surname}
                </div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="EMAIL" />
                </small>
                <div className="text-uppercase">{meeting.Host.email}</div>
              </div>
              <div>
                <small className="font-weight-bold">
                  <FormattedMessage id="CELLPHONE" />
                </small>
                <div className="text-uppercase">{meeting.Host.cellphone}</div>
              </div>
            </div>
            <hr />
            <div>
              <Typography color="secondary">
                <FormattedMessage id="GUESTS" />
              </Typography>
              {meeting.Invitations.map((invitation, i) => {
                return (
                  <div key={i}>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="ID" />
                      </small>
                      <div className="text-uppercase">
                        <Link
                          to={`/app/users/${invitation.Guest.id}/edit`}
                          target="_blank"
                        >
                          {invitation.Guest.id}
                        </Link>
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="NAME" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.Guest.name} {invitation.Guest.surname}
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="EMAIL" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.Guest.email}
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="CELLPHONE" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.Guest.cellphone}
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="CANCELLED AT" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.cancelled_at
                          ? moment(invitation.cancelled_at).format(
                              'DD-MM-YYYY HH:mm'
                            )
                          : '-'}
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="CONFIRMED AT" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.confirmed_at
                          ? moment(invitation.confirmed_at).format(
                              'DD-MM-YYYY HH:mm'
                            )
                          : '-'}
                      </div>
                    </div>
                    <div>
                      <small className="font-weight-bold">
                        <FormattedMessage id="REJECTED AT" />
                      </small>
                      <div className="text-uppercase">
                        {invitation.rejected_at
                          ? moment(invitation.rejected_at).format(
                              'DD-MM-YYYY HH:mm'
                            )
                          : '-'}
                      </div>
                    </div>
                    {invitation.AccessKey && (
                      <div>
                        <small className="font-weight-bold">
                          <FormattedMessage id="ACCESS CODE" />
                        </small>
                        <div className="text-uppercase">
                          {invitation.AccessKey.key} | ID:{' '}
                          {invitation.AccessKey.core_id}
                        </div>
                      </div>
                    )}
                    <hr />
                  </div>
                );
              })}
            </div>
            <hr />
            {meeting.Secretary && (
              <div>
                <Typography color="secondary">
                  <FormattedMessage id="SECRETARY" />
                </Typography>
                <div>
                  <small className="font-weight-bold">
                    <FormattedMessage id="ID" />
                  </small>
                  <div className="text-uppercase">{meeting.Host.id}</div>
                </div>
                <div>
                  <small className="font-weight-bold">
                    <FormattedMessage id="NAME" />
                  </small>
                  <div className="text-uppercase">
                    {meeting.Host.name} {meeting.Host.surname}
                  </div>
                </div>
                <div>
                  <small className="font-weight-bold">
                    <FormattedMessage id="EMAIL" />
                  </small>
                  <div className="text-uppercase">{meeting.Host.email}</div>
                </div>
                <div>
                  <small className="font-weight-bold">
                    <FormattedMessage id="CELLPHONE" />
                  </small>
                  <div className="text-uppercase">{meeting.Host.cellphone}</div>
                </div>
                <hr />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <hr />
            <Button
              onDoubleClick={destroy}
              variant="contained"
              color="secondary"
              type="button"
            >
              <FormattedMessage id="Delete meeting (double click)" />
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};
