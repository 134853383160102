import * as React from 'react';
import { Formik } from 'formik';
import { Input, Select } from '../Shared/BsInput';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ContainerCard from '../Shared/container-card';
import { AddRevisionSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={values.description}
            onChange={handleChange}
            name="description"
            label={
              <FormattedMessage id="Short description (only visible by admins)" />
            }
            errors={errors}
          />
          <Select
            value={values.type}
            onChange={handleChange}
            name="type"
            label={<FormattedMessage id="Revision for" />}
            options={[
              { value: 'TOS', label: 'Terms of service' },
              { value: 'PRIVACY', label: 'Privacy policy' },
            ]}
            errors={errors}
          />
          <p>
            <FormattedMessage
              id="Creating a new revision of a type will force every user to accept the changes before continuing using the app. The revision can, however,
            be deleted and users won't be disturbed."
            />
          </p>
          <hr />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, record, isLoading }) => {
  if (isLoading) {
    return null;
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i>&nbsp;
          <FormattedMessage id="Create revision" />
        </Typography>
      }
      content={
        <Formik
          initialValues={record}
          validationSchema={AddRevisionSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await onSubmit(values);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderForm {...p} />}
        />
      }
    />
  );
};
