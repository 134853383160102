import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import MeetingView from '../../components/Meetings/view';
import * as MeetingsApi from '../../api/meetings';

class MeetingViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingId: this.props.match.params.meetingId,
      meeting: null,
      isLoading: true,
    };

    this.destroy = this.destroy.bind(this);
  }

  async destroy() {
    const onConfirmClick = async () => {
      await MeetingsApi.destroy(this.state.meetingId);

      toast.success(
        this.props.intl.formatMessage({ id: 'The meeting was deleted' })
      );
      this.props.history.push('/app/meetings');
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Delete meeting? This action is irreversible',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  render() {
    return (
      <MeetingView
        meeting={this.state.meeting}
        isLoading={this.state.isLoading}
        destroy={this.destroy}
      />
    );
  }

  async componentDidMount() {
    const { data } = await MeetingsApi.get(this.state.meetingId);

    this.setState({
      meeting: data,
      isLoading: false,
    });
  }
}

export default injectIntl(MeetingViewContainer);
