import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';

export default ({ progress }) => {
  return (
    <Dialog open={true} fullWidth maxWidth="lg" style={{ zIndex: '5035' }}>
      <Box px={3} pt={3}>
        <Typography component="div">
          <FormattedMessage id="Please wait while the users are being uploaded. Do not close the window" />
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" p={3}>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {progress}%
          </Typography>
        </Box>
      </Box>
    </Dialog>
  );
};
