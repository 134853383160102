import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import CompaniesForm from '../../components/MainCompanies/form';
import * as CompaniesApi from '../../api/main-companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {
        name: '',
        layout: 'default',
        logo: null,
        background: null,
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    await CompaniesApi.create(
      _.pick(body, ['name', 'logo', 'background', 'layout'])
    );

    toast.success(
      this.props.intl.formatMessage({ id: 'The company has been created' })
    );
    this.props.history.push(`/app/main-companies`);
  }

  render() {
    return (
      <CompaniesForm company={this.state.company} onSubmit={this.onSubmit} />
    );
  }
}

export default injectIntl(CompaniesCreateContainer);
