import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { injectIntl } from 'react-intl';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';
import QueuesIndex from '../../components/Queues/index';
import * as QueuesApi from '../../api/queue';

class QueuesIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queues: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        id: '',
        invitation_id: '',
        pending: '1',
        order: 'date desc',
      },
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.forceRun = this.forceRun.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  async destroy(id) {
    const onConfirmClick = async () => {
      await QueuesApi.destroy(id);

      toast.success(
        this.props.intl.formatMessage({ id: 'The record was destroyed' })
      );
      this.onPageChange({ page: 1 });
    };

    confirmAlert({
      message: this.props.intl.formatMessage({
        id: 'Delete queue record? The invitation will end up without an access code and this can not be undone',
      }),
      customUI: props => <ConfirmDialog {...props} {...{ onConfirmClick }} />,
    });
  }

  async forceRun(id) {
    await QueuesApi.forceRun(id);
    toast.success(this.props.intl.formatMessage({ id: 'The queue was ran' }));

    this.onPageChange({ page: 1 });
  }

  async onPageChange({ page, ...where }) {
    const { data, headers } = await QueuesApi.list({
      limit: 20,
      offset: (page - 1) * 20,
      ...(_.isEmpty(where) ? this.state.where : where),
    });

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      queues: data,
      pages,
      page,
      where: _.isEmpty(where) ? this.state.where : where,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <QueuesIndex
        queues={this.state.queues}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        forceRun={this.forceRun}
        destroy={this.destroy}
      />
    );
  }
}

export default injectIntl(QueuesIndexContainer);
