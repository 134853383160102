import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    display: 'flex',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    textTransform: 'lowercase !important',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f5f1ee',
    minHeight: '100vh',
  },
}));

export default props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar)}>
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <img
              src="https://podesubir-public.s3-sa-east-1.amazonaws.com/podesubir-logo-green-gray.png"
              alt="logo"
              style={{ maxWidth: '100px' }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <main className={clsx(classes.content)}>{props.children}</main>
    </div>
  );
};
