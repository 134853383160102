import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import * as _ from 'lodash';
import { Input } from '../Shared/BsInput';
import { ChangePwdSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="password"
        value={values.password}
        onChange={handleChange}
        name="password"
        label={<FormattedMessage id="New password" />}
        errors={errors}
      />
      <Input
        type="password"
        value={values.password_confirm}
        onChange={handleChange}
        name="password_confirm"
        label={<FormattedMessage id="Confirm new password" />}
        errors={errors}
      />
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-block btn-dark"
          disabled={isSubmitting}
        >
          <FormattedMessage id="Change password" />
        </button>
      </div>
    </form>
  );
};

export default ({ onSubmit }) => {
  return (
    <div className="row">
      <div className="col-md-4 offset-md-4 col-xs-12">
        <div className="card">
          <div className="card-header">
            <i className="fa fa-id-card-o"></i>{' '}
            <FormattedMessage id="Change password" />
          </div>
          <div className="card-body">
            <Formik
              initialValues={{
                password: '',
                password_confirm: '',
              }}
              validationSchema={ChangePwdSchema}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => <RenderForm {...p} />}
            />
            <div className="text-center">
              <Link to="/app/perfil">
                <FormattedMessage id="Cancel" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
