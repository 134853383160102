import * as React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Input } from '../Shared/BsInput';
import { ProfileSchema } from '../../validation-schemas';

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Input
        type="text"
        value={values.name}
        onChange={handleChange}
        name="name"
        label={<FormattedMessage id="Name" />}
        errors={errors}
      />
      <Input
        type="text"
        value={values.surname}
        onChange={handleChange}
        name="surname"
        label={<FormattedMessage id="Surname" />}
        errors={errors}
      />
      <Input
        type="email"
        value={values.email}
        onChange={handleChange}
        name="email"
        label={<FormattedMessage id="Email" />}
        errors={errors}
      />
      <div className="form-group">
        <button
          type="submit"
          className="btn btn-block btn-dark"
          disabled={isSubmitting}
        >
          <FormattedMessage id="Update information" />
        </button>
      </div>
    </form>
  );
};

export default ({ onSubmit, isLoading, profile }) => {
  return (
    !isLoading && (
      <Formik
        initialValues={profile}
        enableReinitialize={true}
        validationSchema={ProfileSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const data = _.pick(values, ['name', 'surname', 'email']);

            await onSubmit(data);
            setSubmitting(false);
          } catch (err) {
            if (err.response && err.response.data.errors) {
              setErrors(err.response.data.errors);
            }

            setSubmitting(false);
          }
        }}
        render={p => <RenderForm {...p} />}
      />
    )
  );
};
