import * as React from 'react';
import * as _ from 'lodash';
import AuditIndex from '../../components/Audit/index';
import moment from 'moment';

import * as DekstopApp from '../../api/desktop-app';

export default class MeetingsIndexContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      registers: [],
      allRegisters: [],
      pages: 0,
      page: 1,
      isLoading: true,
      where: {
        user: '',
        init_date: moment().subtract(3, 'months'),
        end_date: moment(),
      },
      users: [],
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.getRegisters = this.getRegisters.bind(this);
  }

  async onPageChange({ page }) {
    const initRegister = (page - 1) * 20
    const registerPaginated = this.state.allRegisters.slice(initRegister, initRegister + 20)
    
    this.setState({
      registers: registerPaginated,
      page,
    });
  }

  async getRegisters({ user, init_date, end_date}) {
    const { data } = await DekstopApp.get({
      user, 
      init_date, 
      end_date
    })

    const registerPaginated = data.slice(0, 20)

    this.setState({
      registers: registerPaginated,
      allRegisters: data,
      page: 1,
      pages: data.length,
      where: { user, init_date, end_date},
    });
  }

  async componentDidMount() {
    const { data } = await DekstopApp.listUsers()
    this.setState({ users: data });
  }

  render() {
    return (
      <AuditIndex
        registers={this.state.registers}
        allRegisters={this.state.allRegisters}
        users={this.state.users}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
        getRegisters={this.getRegisters}
      />
    );
  }
}
