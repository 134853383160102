import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import BuildingForm from '../../components/Buildings/form';
import * as BuildingsApi from '../../api/buildings';
import * as GatewaysApi from '../../api/gateways';

const nullify = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (_.isString(obj[key]) && obj[key].trim().length === 0) {
      acc[key] = null;
    } else {
      acc[key] = obj[key];
    }

    return acc;
  }, {});
};

class BuildingCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      building: {
        name: '',
        address: '',
        access_types: ['qr', 'barcode', 'nfc'],
        access_source: 'manual',
        facility_codes: [],
        code_algorithm: 'PLAIN',
        code_type: 'STATIC',
        config: {
          use_list_keys: false,
        },
        totp: {
          encryption_key: '',
          encryption_iv: '',
          step: '',
          secret: '',
          timezone: '',
          digits: '',
        },
        code_spec: '',
        access_gateways: [],
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { data: gateways } = await GatewaysApi.list({ attributes: 'id,name', where: { disabled: false } });

    this.setState(prev => ({
      ...prev,
      building: {
        ...prev.building,
        gateways
      }
    }));
  }

  async onSubmit(body) {
    body.totp = nullify(body.totp);

    delete body.gateways;

    const { data } = await BuildingsApi.create(body);

    toast.success(
      this.props.intl.formatMessage({
        id: 'The building was created. You can now start adding users',
      })
    );
    this.props.history.push(`/app/buildings/${data.id}/users`);
  }

  render() {
    return (
      <BuildingForm building={this.state.building} onSubmit={this.onSubmit} />
    );
  }
}

export default injectIntl(BuildingCreateContainer);
