import * as React from 'react';
import { connect } from 'react-redux';
import { loggedIn } from '../../actions/auth';
import LoginForm from '../../components/Login';
import * as AuthApi from '../../api/auth';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const { data } = await AuthApi.login({
      ...body,
      device_id: '8532',
    });

    this.props.loggedIn(data);
  }

  render() {
    return <LoginForm onSubmit={this.onSubmit} />;
  }
}

const mapDispatchToProps = dispatch => ({
  loggedIn: data => dispatch(loggedIn(data)),
});

export default connect(null, mapDispatchToProps)(LoginContainer);
