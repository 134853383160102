import * as React from 'react';
import * as _ from 'lodash';
import { injectIntl } from 'react-intl';
import RegistrationStatsComponent from '../../components/Users/registration-stats';
import * as UsersApi from '../../api/users';

class RegistrationStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const { data } = await UsersApi.registrationStats(body);

    this.setState({ data });
  }

  render() {
    return (
      <RegistrationStatsComponent
        onSubmit={this.onSubmit}
        data={this.state.data}
      />
    );
  }
}

export default injectIntl(RegistrationStats);
