import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import { Input, Select } from '../Shared/BsInput';
import ListTable from '../Shared/list-table';
import Modal from 'react-bootstrap4-modal';
import { useState } from 'react';
import * as ProcessesApi from '../../api/processes';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

const RenderTable = ({ onPageChange, pages, page, buildings, onOpenModal }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>
            <FormattedMessage id="ID" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Name" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Address" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="Access types" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {buildings.map((b, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{b.id}</TableCell>
                <TableCell>{b.name}</TableCell>
                <TableCell>{b.address}</TableCell>
                <TableCell>{b.access_types.join(', ').toUpperCase()}</TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/edit`}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    Edit
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/users`}
                    variant="outlined"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Users" />
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/companies`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Manage companies" />
                  </Button>
                  <Button
                    component={Link}
                    to={`/app/buildings/${b.id}/keys`}
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '5px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Access codes" />
                  </Button>
                  {b.access_source === 'manual-with-online-interaction' && (
                    <Button
                      onClick={() => onOpenModal(b.id)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginBottom: '5px' }}
                      fullWidth
                    >
                      <FormattedMessage id="Synchronize Equipment" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.address}
            onChange={handleChange}
            name="address"
            label={<FormattedMessage id="Address" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.access_source}
            onChange={handleChange}
            name="access_source"
            label={<FormattedMessage id="Access source" />}
            options={[
              { value: 'manual', label: <FormattedMessage id="Manual" /> },
              { value: 'online', label: <FormattedMessage id="Online" /> },
              {
                value: 'cenu-north',
                label: (
                  <FormattedMessage id="Access gateway: Cenu North Tower" />
                ),
              },
              {
                value: 'cenu-west',
                label: (
                  <FormattedMessage id="Access gateway: Cenu West Tower" />
                ),
              },
              {
                value: 'access-gateway',
                label: <FormattedMessage id="Access gateway: Iluminare" />,
              },
              {
                value: 'backed-access-gateway',
                label: (
                  <FormattedMessage id="Access gateway: Iluminare (show manual access code until ready)" />
                ),
              },
              {
                value: 'manual-with-online-interaction',
                label: <FormattedMessage id="Manual retrieved online" />,
              },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.order}
            onChange={handleChange}
            name="order"
            label={<FormattedMessage id="Order" />}
            options={[
              { value: 'id desc', label: 'ID desc' },
              { value: 'id asc', label: 'ID asc' },
              { value: 'name desc', label: 'Name desc' },
              { value: 'name asc', label: 'Name asc' },
              { value: 'address desc', label: 'Address desc' },
              { value: 'address asc', label: 'Address asc' },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, pages, page, buildings }) => {
  const [openModal, setOpenModal] = useState(false);
  const [buildingId, setBuildingId] = useState(0);
  const [lastProcess, setLastProcess] = useState();
  const [loading, setLoading] = useState(true);

  const sincronizeUsers = async () => {
    const { data } = await ProcessesApi.createProcess({
      process_name: 'export-gateway',
      building_id: buildingId,
      type: 'missing_only',
    });
    toast.success('Synchronization started');
    setOpenModal(false);
  };

  const sincronizeUsersFull = async () => {
    const { data } = await ProcessesApi.createProcess({
      process_name: 'export-gateway',
      building_id: buildingId,
      type: 'all',
    });
    toast.success('Synchronization started');
    setOpenModal(false);
  };

  const onOpenModal = async id => {
    setLoading(true);
    setBuildingId(id);
    setOpenModal(true);
    try {
      const { data: processes } = await ProcessesApi.getLastProcess(id);
      setLastProcess(processes?.[0]);
    } catch (e) {
      setOpenModal(false);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-building-o"></i>{' '}
          <FormattedMessage id="Buildings" />
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              to={`/app/buildings/import-users`}
            >
              <FormattedMessage id="Import users" />
            </Button>
            &nbsp;
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/buildings/create`}
            >
              <FormattedMessage id="Create building" />
            </Button>
            <hr />
          </div>
          <Formik
            initialValues={{
              id: '',
              name: '',
              address: '',
              access_source: '',
              order: 'name asc',
            }}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                await onPageChange({ page: 1, ...values });
                setSubmitting(false);
              } catch (err) {
                if (err.response && err.response.data.errors) {
                  setErrors(err.response.data.errors);
                }

                setSubmitting(false);
              }
            }}
            render={RenderForm}
          />
          <hr />
          {!buildings.length && <NoRecords />}
          {!!buildings.length && (
            <RenderTable
              {...{ onPageChange, pages, page, buildings, onOpenModal }}
            />
          )}
          <Modal visible={openModal}>
            <div className="modal-header">
              <h5 className="modal-title">
                <FormattedMessage id="Synchronizing equipment" />
              </h5>
            </div>
            <div className="modal-body">
              {loading ? (
                <LinearProgress />
              ) : !lastProcess ||
                lastProcess.status === 'completed' ||
                lastProcess.status === 'failed' ? (
                <>
                  <Button
                    onClick={sincronizeUsers}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '15px' }}
                    fullWidth
                  >
                    <FormattedMessage id="Only the missing" />
                  </Button>
                  <Button
                    onClick={sincronizeUsersFull}
                    variant="outlined"
                    color="primary"
                    size="small"
                    fullWidth
                  >
                    <FormattedMessage id="Complete" />
                  </Button>
                  {lastProcess && (
                    <div style={{ marginTop: '1rem' }}>
                      <FormattedMessage
                        id="Finished process message"
                        values={{
                          processId: lastProcess.id,
                          createdAt: new Date(
                            lastProcess.created_at
                          ).toLocaleDateString(),
                          status: (
                            <FormattedMessage id={lastProcess.status}>
                              {txt => (
                                <span
                                  style={{
                                    color:
                                      lastProcess.status === 'completed'
                                        ? 'green'
                                        : 'red',
                                  }}
                                >
                                  {txt}
                                </span>
                              )}
                            </FormattedMessage>
                          ),
                          finishedAt: new Date(
                            lastProcess.last_executed_at
                          ).toLocaleDateString(),
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <FormattedMessage
                    id="Export process message"
                    values={{
                      processId: lastProcess.id,
                      createdAt: new Date(
                        lastProcess.created_at
                      ).toLocaleDateString(),
                    }}
                  />
                  {Number(lastProcess.attempts) >= 1 &&
                  lastProcess.status === 'pending' ? (
                    <FormattedMessage
                      id="Failed process with retry"
                      values={{
                        failedAt: new Date(
                          lastProcess.last_executed_at
                        ).toLocaleDateString(),
                      }}
                    >
                      {txt => <span>, {txt} </span>}
                    </FormattedMessage>
                  ) : (
                    <FormattedMessage id={lastProcess.status} />
                  )}
                </>
              )}
            </div>
            <div className="modal-footer">
              <Button
                onClick={() => setOpenModal(false)}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="Close" />
              </Button>
            </div>
          </Modal>
        </React.Fragment>
      }
    />
  );
};
