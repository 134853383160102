import * as React from 'react';
import { Formik } from 'formik';
import { Input, Select, Checkbox } from '../Shared/BsInput';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ContainerCard from '../Shared/container-card';
import { AddCompanySchema, EditCompanySchema } from '../../validation-schemas';
import { File } from '../MainCompanies/form';
import Modal from 'react-bootstrap4-modal';
import { removeCompany } from '../../api/buildings';
import { useHistory } from 'react-router-dom';


const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  mainCompanies,
  setFieldValue,
  building
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const history = useHistory();

  let gatewaysOptions = [];
  if (building?.gateways){
    gatewaysOptions = gatewaysOptions.concat(building?.gateways?.map((gateway) => {
      return { value: gateway.id, label: gateway.name }
    }));
  }

  const onConfirmExclude = async () => {
    await removeCompany(values.id)
    setShowModal(false)
    history.push('/app/buildings');
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Modal visible={showModal} >
        <div className="modal-header">
          <h5 className="modal-title"><FormattedMessage id="Are you sure about that?" /></h5>
        </div>
        <div className="modal-body">
          <FormattedMessage id="If you continue, the company will be removed and its users disconnected!" />
        </div>
        <div className="modal-footer">
          <Button
            onClick={onConfirmExclude}
            variant="contained"
            color="primary"
          >
            <FormattedMessage id="Continue" />
          </Button>
          <Button
            onClick={() => setShowModal(false)}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="Cancel" />
          </Button>
        </div>
      </Modal>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.floor}
            onChange={handleChange}
            name="floor"
            label={<FormattedMessage id="Floor" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.room}
            onChange={handleChange}
            name="room"
            label={<FormattedMessage id="Room" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Input
            type="text"
            value={values.phone}
            onChange={handleChange}
            name="phone"
            label={<FormattedMessage id="Phone" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={String(values.access_before_minutes)}
            onChange={handleChange}
            name="access_before_minutes"
            label={
              <FormattedMessage id="Allow access before meeting starts (minutes)" />
            }
            errors={errors}
            help="Minutes before the actual meeting that we will create the access in the building"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={String(values.show_code_before_minutes)}
            onChange={handleChange}
            name="show_code_before_minutes"
            label={
              <FormattedMessage id="Show access code before meeting starts (minutes)" />
            }
            errors={errors}
            help="Minutes before the meeting that we will show the guest the code / announce button"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            value={values.parent_id}
            onChange={handleChange}
            name="parent_id"
            label={<FormattedMessage id="Main company" />}
            errors={errors}
            options={[
              { value: null, label: 'None' },
              ...mainCompanies.map(c => ({ value: c.id, label: c.name })),
            ]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            value={values.layout}
            onChange={handleChange}
            name="layout"
            label={<FormattedMessage id="Layout" />}
            options={[
              { value: 'default', label: 'Default' },
              { value: 'left_logo', label: 'Logo on the left' },
              { value: 'right_logo', label: 'Logo on the right' },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: building?.access_source?.includes('online', 'manual-with-online-interaction') ? 'inherit' : 'none'}}>
            <Select
              value={values.access_gateways}
              onChange={handleChange}
              name="access_gateways"
              label={<FormattedMessage id="Access Gateways" />}
              options={gatewaysOptions}
              errors={errors}
              multiple
            />
          </div>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <File
              name="logo"
              preview="logo_url"
              label={<FormattedMessage id="Company logo" />}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <File
              name="background"
              preview="background_url"
              label={<FormattedMessage id="Cracha background" />}
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div>
            <Checkbox
              value={!!values.allow_arrival_announcement}
              onChange={handleChange}
              name="allow_arrival_announcement"
              errors={errors}
            />{' '}
            <FormattedMessage id="Allow the announce arrival functionality" />
          </div>
        </Grid>
       
        {values.id ? (
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowModal(true)}
              disabled={isSubmitting}
              fullWidth
            >
              <FormattedMessage id="Remove" />
            </Button>
          </Grid>
        ) : null}
         <Grid item xs={0} md={6}/>
         <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, company, isLoading, mainCompanies, building }) => {
  if (isLoading) {
    return null;
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i>&nbsp;
          {company.id ? (
            <FormattedMessage id="Edit company" />
          ) : (
            <FormattedMessage id="Create company" />
          )}
        </Typography>
      }
      content={
        <Formik
          initialValues={company}
          validationSchema={company.id ? EditCompanySchema : AddCompanySchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await onSubmit(values);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderForm {...p} {...{ mainCompanies, building }} />}
        />
      }
    />
  );
};
