import * as React from 'react';
import '../../assets/css/loading.css';

export default class Loading extends React.Component {
  render() {
    const { visible } = this.props;
    return (
      <div
        className="ajax-loader-container"
        style={{ display: visible ? 'block' : 'none' }}
      >
        <div className="ajax-loader"></div>
      </div>
    );
  }
}
