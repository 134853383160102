import request from './index';

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/meetings',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/admin/meetings/${id}`,
  });
}

export function destroy(id) {
  return request({
    method: 'DELETE',
    path: `/api/admin/meetings/${id}`,
  });
}
