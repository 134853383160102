import axios from 'axios';
import isObject from 'lodash/isObject';
import request, { catchError } from './index';
import { req, res } from '../actions/ui';
import store from '../store';

const BASEURL = process.env.REACT_APP_API_URL;

export function listPublic() {
  return request({
    method: 'GET',
    path: '/api/companies/main-companies',
  });
}

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/main-companies',
  });
}

export function get(id) {
  return request({
    method: 'GET',
    path: `/api/admin/main-companies/${id}`,
  });
}

export function create(body) {
  const fd = new FormData();

  Object.keys(body)
    .filter(key => !!body[key])
    .forEach(key => {
      fd.append(key, body[key]);
    });

  store.dispatch(req());

  return axios
    .post(`${BASEURL}/api/admin/main-companies`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}

export function update(id, body) {
  const fd = new FormData();

  Object.keys(body)
    .filter(key => !!body[key])
    .forEach(key => {
      fd.append(key, body[key]);
    });

  store.dispatch(req());

  return axios
    .put(`${BASEURL}/api/admin/main-companies/${id}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      store.dispatch(res());

      return response;
    })
    .catch(catchError);
}
