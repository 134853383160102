import * as React from 'react';
import { Formik } from 'formik';
import { Button, Grid, Typography, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import ContainerCard from '../Shared/container-card';
import { Input, Select } from '../Shared/BsInput';
import { MainCompanySchema } from '../../validation-schemas';

export const File = ({
  name,
  preview,
  setFieldValue,
  errors,
  label,
  values,
}) => {
  return (
    <Box my={2} p={1} boxShadow={6}>
      <Typography>{label}</Typography>
      {!values[preview] && (
        <Button type="button" component="label" variant="outlined" fullWidth>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={e => {
              e.preventDefault();

              const reader = new FileReader();
              const file = e.target.files[0];

              reader.onloadend = () => {
                setFieldValue(name, file);
                setFieldValue(preview, reader.result);
              };

              reader.readAsDataURL(file);
            }}
            name={name}
            accept=".jpg,.jpeg,.png,.bmp,.gif"
          />
          <FormattedMessage id="Upload" />
        </Button>
      )}

      {values[preview] && (
        <div className="text-center">
          <img
            alt=""
            src={values[preview]}
            className="img-thumbnail"
            style={{ maxWidth: '40%' }}
          />
          <div
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => {
              setFieldValue(name, null);
              setFieldValue(preview, null);
            }}
          >
            <i className="fa fa-times"></i> <FormattedMessage id="Remove" />
          </div>
        </div>
      )}
    </Box>
  );
};
const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3} justify="center">
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
          <Select
            value={values.layout}
            onChange={handleChange}
            name="layout"
            label={<FormattedMessage id="Layout" />}
            options={[
              { value: 'default', label: 'Default' },
              { value: 'left_logo', label: 'Logo on the left' },
              { value: 'right_logo', label: 'Logo on the right' },
            ]}
          />
          <File
            name="logo"
            preview="logo_url"
            label={<FormattedMessage id="Company logo" />}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <File
            name="background"
            preview="background_url"
            label={<FormattedMessage id="Cracha background" />}
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />

          <Button
            className="mt-3"
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onSubmit, company, isLoading }) => {
  if (isLoading) {
    return null;
  }

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-pencil"></i>&nbsp;
          {company.id ? (
            <FormattedMessage id="Edit company" />
          ) : (
            <FormattedMessage id="Create company" />
          )}
        </Typography>
      }
      content={
        <Formik
          initialValues={company}
          validationSchema={MainCompanySchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            try {
              await onSubmit(values);
            } catch (err) {
              if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
              }

              setSubmitting(false);
            }
          }}
          render={p => <RenderForm {...p} />}
        />
      }
    />
  );
};
