import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import CompaniesForm from '../../components/MainCompanies/form';
import * as CompaniesApi from '../../api/main-companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.match.params.companyId,
      company: null,
      isLoading: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const clean = _.pick(body, ['name', 'logo', 'background', 'layout']);

    await CompaniesApi.update(this.state.companyId, clean);

    toast.success(
      this.props.intl.formatMessage({ id: 'The company has been updated' })
    );
    this.props.history.push(`/app/main-companies`);
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <CompaniesForm
        company={this.state.company}
        onSubmit={this.onSubmit}
        isLoading={this.state.isLoading}
      />
    );
  }

  async componentDidMount() {
    const { data } = await CompaniesApi.get(this.state.companyId);

    this.setState({
      company: {
        ...data,
        logo: null,
      },
      isLoading: false,
    });
  }
}

export default injectIntl(CompaniesCreateContainer);
