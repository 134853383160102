import * as React from 'react';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import ImportUsers from '../../components/Buildings/import-users';
import * as BuildingsApi from '../../api/buildings';
import ProgressDialog from '../../components/Shared/progress-dialog';

class BuildingImportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        building_id: null,
        company_id: null,
        file: null,
      },
      buildings: [],
      companies: [],
      isLoading: true,
      users: null,
      error: false,
      progress: 0,
      uploading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.fetchCompanies = this.fetchCompanies.bind(this);
    this.restart = this.restart.bind(this);
  }

  restart() {
    this.setState({
      form: {
        building_id: null,
        company_id: null,
        file: null,
      },
      companies: [],
      users: null,
      error: false,
      progress: 0,
      uploading: false,
    });
  }

  async onSubmit(values) {
    this.setState({ form: values });

    const { file, ...rest } = values;

    const { data } = await BuildingsApi.importUsersSimulate(file, rest);

    this.setState({
      progress: 0,
      uploading: false,
      users: data.reduce((acc, cur) => {
        if (cur.valid) {
          return acc.concat([cur]);
        }

        return acc.concat([
          cur,
          {
            name: cur.errors.name || null,
            surname: cur.errors.surname || null,
            birthdate: cur.errors.birthdate || null,
            email: cur.errors.email || null,
            cellphone: cur.errors.cellphone || null,
            id_type: cur.errors.id_type || null,
            id_number: cur.errors.id_number || null,
            cpf: cur.errors.cpf || null,
            company: cur.errors.company || null,
            company_role: cur.errors.company_role || null,
            facility_code: cur.errors.facility_code || null,
            access_code: cur.errors.access_code || null,
            errorRow: true,
          },
        ]);
      }, []),
    });
  }

  async onConfirm() {
    this.setState({
      progress: 0,
      uploading: true,
    });
    const { file, ...rest } = this.state.form;

    const { data } = await BuildingsApi.importUsers(file, rest, e => {
      const array = e.currentTarget.response.split('-').filter(i => i.length);

      const progress = parseInt(array[array.length - 1], 10);

      if (!isNaN(progress)) {
        this.setState({ progress });
      }
    });
    toast.success(
      this.props.intl.formatMessage({ id: 'The users have been imported' })
    );

    const array = data.split('-');
    const json = JSON.parse(array[array.length - 1]);

    if (json.errors.length) {
      this.setState({
        users: json.errors,
        error: true,
        uploading: false,
        progress: 0,
      });
    } else {
      this.restart();
    }
  }

  async fetchCompanies(buildingId) {
    const { data } = await BuildingsApi.listCompanies(buildingId, {
      limit: 10000,
      order: 'name desc',
      attributes: 'id,name',
    });

    this.setState({ companies: data });
  }

  render() {
    return (
      <>
        {this.state.uploading && (
          <ProgressDialog progress={this.state.progress} />
        )}
        <ImportUsers
          onSubmit={this.onSubmit}
          buildings={this.state.buildings}
          companies={this.state.companies}
          users={this.state.users}
          onConfirm={this.onConfirm}
          fetchCompanies={this.fetchCompanies}
          restart={this.restart}
          error={this.state.error}
        />
      </>
    );
  }

  async componentDidMount() {
    const { data } = await BuildingsApi.list({
      limit: 10000,
      order: 'name asc',
      attributes: 'id,name',
    });

    this.setState({
      buildings: data,
    });
  }
}

export default injectIntl(BuildingImportContainer);
