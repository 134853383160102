import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ProfileForm from './form';
import UploadPicture from '../../containers/Profile/upload-picture';

export default ({ onFileSubmit, onSubmit, user, profile, isLoading }) => {
  return (
    <div className="row">
      <div className="col-md-4 offset-md-4 col-xs-12">
        <div className="card">
          <div className="card-header">
            <i className="fa fa-id-card-o"></i>{' '}
            <FormattedMessage id="My profile" />
          </div>
          <div className="card-body">
            <UploadPicture
              profile_picture={user.profile_picture}
              onSubmit={onFileSubmit}
            />
            <hr />
            <ProfileForm
              onSubmit={onSubmit}
              profile={profile}
              isLoading={isLoading}
            />
            <div className="text-center">
              <Link to="/app/change-password">
                <FormattedMessage id="Change password" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
