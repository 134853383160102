import * as React from 'react';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import CompaniesForm from '../../components/Companies/form';
import * as GatewaysApi from '../../api/gateways';
import * as BuildingsApi from '../../api/buildings';
import * as MainCompaniesApi from '../../api/main-companies';

class CompaniesCreateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buildingId: this.props.match.params.buildingId,
      building: null,
      isLoading: true,
      company: {
        name: '',
        floor: '',
        room: '',
        access_before_minutes: '0',
        parent_id: null,
        logo: null,
        background: null,
        layout: 'default',
        allow_arrival_announcement: false,
        show_code_before_minutes: 15,
        access_gateways: [],
      },
      mainCompanies: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(body) {
    const clean = _.pick(body, [
      'name',
      'floor',
      'room',
      'access_before_minutes',
      'parent_id',
      'logo',
      'background',
      'layout',
      'allow_arrival_announcement',
      'phone',
      'show_code_before_minutes',
      'access_gateways',
    ]);


    await BuildingsApi.createCompany(
      this.state.buildingId,
      clean
    );

    toast.success(
      this.props.intl.formatMessage({
        id: 'The company has been added to the building',
      })
    );
    this.props.history.push(
      `/app/buildings/${this.state.buildingId}/companies`
    );
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    return (
      <CompaniesForm
        company={this.state.company}
        onSubmit={this.onSubmit}
        isLoading={this.state.isLoading}
        mainCompanies={this.state.mainCompanies}
        building={this.state.building}
      />
    );
  }

  async componentDidMount() {
    const { data } = await MainCompaniesApi.listPublic();

    const { data: building } = await BuildingsApi.get(this.state.buildingId);
    const { data: gateways } = await GatewaysApi.list({ attributes: 'id,name', where: { disabled: false } });

    this.setState({ 
      mainCompanies: data,
      building: {
        ...building,
        gateways
      },
      isLoading: false,
    });
  }
}

export default injectIntl(CompaniesCreateContainer);
