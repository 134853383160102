import React from 'react';
import { Formik, FieldArray } from 'formik';
import { Input, Select } from '../Shared/BsInput';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormattedMessage } from 'react-intl';

const fields = {
  name: <FormattedMessage id="Name" />,
  surname: <FormattedMessage id="Surname" />,
  profile_picture: <FormattedMessage id="Profile picture" />,
  birthdate: <FormattedMessage id="Birthdate" />,
  id_type: <FormattedMessage id="ID type" />,
  id_number: <FormattedMessage id="ID number" />,
  cpf: <FormattedMessage id="CPF" />,
  ID_FRONT: <FormattedMessage id="Documets ID Front picture" />,
  ID_BACK: <FormattedMessage id="Documents ID Back picture" />,
};

const options = [
  'não houve equivalência automática nos dados cadastrados. Revise a qualidade da imagem da frente do documento enviado',
  'não houve equivalência automática nos dados cadastrados. Revise a qualidade da imagem do verso do documento enviado',
  'não houve equivalência automática entre a foto de selfie e a foto do documento cadastrado. Verifique se alguma imagem está fora de foco, pouco iluminada ou com reflexo e substitua por uma nova.',
  'não houve equivalência automática entre a foto de selfie e a foto do documento cadastrado. Verifique se alguma imagem está fora de foco, pouco iluminada ou com reflexo e substitua por uma nova.',
  'não houve equivalência automática entre a foto de selfie e a foto do documento cadastrado. Verifique se alguma imagem está fora de foco, pouco iluminada ou com reflexo e substitua por uma nova.',
  'não houve equivalência automática do número de documento cadastrado. Verifique os números digitados.',
  'não houve equivalência automática do número de CPF cadastrado. Verifique os números digitados.',
  'não houve equivalência automática da data de nascimento cadastrada. Verifique os números digitados.',
  'não houve equivalência automática no nome cadastrado. Verifique o nome digitado.',
  'verificamos a data de nascimento cadastrada e não podemos aprovar o cadastro de usuários com idade inferior a 16 anos. Ficamos contentes com o seu interesse pela Podesubir e, assim que você completar 16 anos, estaremos de braços abertos para aprovar seu cadastro.',
  'não houve equivalência automática da data de nascimento no documento cadastrado. Verifique os números digitados e ',
  'não houve equivalência automática no sobrenome cadastrado. Verifique o sobrenome digitado e a qualidade das imagens enviadas',
  'sua foto de perfil deve ser um selfie, por favor, tire  uma nova foto',
];

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  isValid,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <FieldArray
        name="fields"
        render={arrayHelpers => (
          <div>
            {values.fields.map((field, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={3}>
                  <Select
                    name={`fields.${index}.field`}
                    value={field.field}
                    errors={errors}
                    onChange={handleChange}
                    label={
                      <FormattedMessage id="Field that needs correction" />
                    }
                    options={Object.keys(fields).map(key => ({
                      label: fields[key],
                      value: key,
                    }))}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    options={options}
                    onChange={(e, v) => {
                      setFieldValue(`fields.${index}.reason`, v);
                    }}
                    onInputChange={(e, v) => {
                      setFieldValue(`fields.${index}.reason`, v);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          <FormattedMessage id="Reason of the correction (PT)" />
                        }
                        margin="dense"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    className="mt-4"
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                    color="secondary"
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </Grid>
              </Grid>
            ))}
            <div className="text-right">
              <Button
                className="mt-4"
                type="button"
                onClick={() => arrayHelpers.push({ field: '', reason: '' })}
              >
                <FormattedMessage id="Add new field" />
              </Button>
            </div>
          </div>
        )}
      />
      <hr />
      <div className="text-right">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!values.fields.length}
        >
          <FormattedMessage id="Create review" />
        </Button>
        &nbsp;
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() => handleClose()}
        >
          <FormattedMessage id="Cancel" />
        </Button>
      </div>
    </form>
  );
};

export default function CreateReviewDialog(props) {
  const { open, handleClose, onSubmit } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'xl'}
        fullWidth={true}
      >
        <DialogContent>
          <div style={{ padding: '20px' }}>
            <Formik
              initialValues={{
                fields: [
                  {
                    field: 'name',
                    reason: 'O nome não corresponde à documentação',
                  },
                ],
              }}
              enableReinitialize={true}
              validate={values => {
                const errors = {};

                if (!values.fields.length) {
                  errors['fields.0.field'] = 'Required 2';
                  errors['fields.0.reason'] = 'Required 2';
                }

                values.fields.forEach((field, i) => {
                  if (!field.field) {
                    errors[`fields.${i}.field`] = 'Required';
                  }

                  if (!field.reason) {
                    errors[`fields.${i}.reason`] = 'Required';
                  }
                });

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                  await onSubmit(values);
                  handleClose();
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }}
              render={p => <RenderForm {...p} {...{ handleClose }} />}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
