import request from './index';

export function list(params) {
  return request({
    params,
    method: 'GET',
    path: '/api/admin/queues',
  });
}

export function forceRun(id) {
  return request({
    method: 'PUT',
    path: `/api/admin/queues/${id}`,
  });
}

export function destroy(id) {
  return request({
    method: 'DELETE',
    path: `/api/admin/queues/${id}`,
  });
}

export function errors(id, params) {
  return request({
    params,
    method: 'GET',
    path: `/api/admin/queues/${id}/errors`,
  });
}
