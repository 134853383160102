import * as React from 'react';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import moment from 'moment';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import ListTable from '../Shared/list-table';

const RenderTable = ({ onPageChange, pages, page, emails }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Params</TableCell>
          <TableCell>Invitation Ids</TableCell>
          <TableCell>Created at</TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {emails.map((email, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{email.id}</TableCell>
                <TableCell>
                  <pre>{JSON.stringify(email.params, null, 2)}</pre>
                </TableCell>
                <TableCell>
                  {(email.invitation_ids || []).map((inv, i) => {
                    return <div key={i}>#{inv}</div>;
                  })}
                </TableCell>
                <TableCell>
                  {moment(email.created_at).format('DD-MM-YYYY HH:mm')}
                </TableCell>
              </TableRow>
            );
          })}
        </React.Fragment>
      }
    />
  );
};

export default ({ onPageChange, pages, page, emails }) => {
  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-envelope-open"></i> Scheduler emails processed
        </Typography>
      }
      content={
        <React.Fragment>
          {!emails.length && <NoRecords />}
          {!!emails.length && (
            <RenderTable {...{ onPageChange, pages, page, emails }} />
          )}
        </React.Fragment>
      }
    />
  );
};
