import * as React from 'react';
import * as _ from 'lodash';
import { injectIntl } from 'react-intl';
import ErrorsLog from '../../components/Queues/errors';
import * as QueuesApi from '../../api/queue';

class ErrorsLogContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      pages: 0,
      page: 1,
      isLoading: true,
    };

    this.where = {
      invitation_id: this.props.infos.invitation_id,
      order: 'created_at desc',
    }

    this.onPageChange = this.onPageChange.bind(this);
  }

  async onPageChange({ page, ...where }) {
    const { data, headers } = await QueuesApi.errors(
      this.props.infos.queue_id,
      {
        limit: 20,
        offset: (page - 1) * 20,
        ...(_.isEmpty(where) ? this.where : where),
      }
    );

    const pages = parseInt(headers['x-total-count'], 10);

    this.setState({
      errors: data,
      pages,
      page,
      where: _.isEmpty(where) ? this.where : where,
    });
  }

  async componentDidMount() {
    this.onPageChange({ page: 1 });
  }

  render() {
    return (
      <ErrorsLog
        setOpenModal={this.props.setOpenModal}
        errors={this.state.errors}
        isLoading={this.state.isLoading}
        pages={this.state.pages}
        page={this.state.page}
        onPageChange={this.onPageChange}
      />
    );
  }
}

export default injectIntl(ErrorsLogContainer);
