import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export default () => {
  return (
    <div className="alert alert-info text-center">
      <FormattedMessage id="No records found" />
    </div>
  );
};
