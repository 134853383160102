import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
import NoRecords from '../Shared/NoRecords';
import ContainerCard from '../Shared/container-card';
import { Input, Select } from '../Shared/BsInput';
import ListTable from '../Shared/list-table';
import '../../assets/css/bootstrap.theme.css'

const RenderTable = ({ onPageChange, pages, page, gateways }) => {
  return (
    <ListTable
      onPageChange={onPageChange}
      pages={pages}
      page={page}
      head={
        <TableRow>
          <TableCell><FormattedMessage id="ID" /></TableCell>
          <TableCell><FormattedMessage id="Name" /></TableCell>
          <TableCell></TableCell>
        </TableRow>
      }
      body={
        <React.Fragment>
          {
            gateways.map((b, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{b.id}</TableCell>
                  <TableCell>{b.name}</TableCell>
                  <TableCell style={{maxWidth: 100}}>
                    <Button
                      component={Link}
                      to={`/app/gateways/${b.id}/edit`}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginBottom: '5px' }}
                      fullWidth
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          }
        </React.Fragment>
      }
    />
  );
};

const RenderForm = ({
  values,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.id}
            onChange={handleChange}
            name="id"
            label={<FormattedMessage id="ID" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Input
            type="text"
            value={values.name}
            onChange={handleChange}
            name="name"
            label={<FormattedMessage id="Name" />}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            value={values.order}
            onChange={handleChange}
            name="order"
            label={<FormattedMessage id="Order" />}
            options={[
              { value: 'id desc', label: 'ID desc' },
              { value: 'id asc', label: 'ID asc' },
              { value: 'name desc', label: 'Name desc' },
              { value: 'name asc', label: 'Name asc' },
            ]}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <Button
            style={{ marginTop: '16px' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            <FormattedMessage id="Filter" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ({ onPageChange, pages, page, gateways }) => {

  return (
    <ContainerCard
      xs={12}
      md={12}
      header={
        <Typography variant="h6">
          <i className="fa fa-building-o"></i> <FormattedMessage id="Gateways" />
        </Typography>
      }
      content={
        <React.Fragment>
          <div className="text-right">
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/app/gateways/create`}
            >
              <FormattedMessage id="Create gateway" />
            </Button>
            <hr/>
          </div>
          <Formik
            initialValues={{
              id: '',
              name: '',
              order: 'id desc',
            }}
            enableReinitialize={true}
            onSubmit={
              async (values, { setSubmitting, setErrors }) => {
                try {
                  await onPageChange({ page: 1, ...values });
                  setSubmitting(false);
                } catch (err) {
                  if (err.response && err.response.data.errors) {
                    setErrors(err.response.data.errors);
                  }

                  setSubmitting(false);
                }
              }
            }
            render={RenderForm}
          />
          <hr />
          {
            !gateways.length && <NoRecords />
          }
          {
            !!gateways.length && <RenderTable {...{ onPageChange, pages, page, gateways }} />
          }
        </React.Fragment>
      }

    />
  );
}
